import React, {useMemo} from 'react';
import styled from "styled-components";
import CheckableOption from '../Option/CheckableOption';
import {getInDataState, getInState, getInTarifsState} from "../../Utils/storeHelpers";
import {useSelector} from "react-redux";
import {green} from "../../Utils/styleConstants";
import {resolvePrice} from "../../Utils/lePack/tarif";
import WithCounterChoiceOption from "../Option/WithCounterChoiceOption";
import PerformanceOptionGroup from "./PerformanceOptionGroup";

const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const SubTotalContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 20px 11.375px 11.375px;
    border-radius: 10px;
    overflow: hidden;
    font-weight: bold;
    background: #fafafa;
    width: 100%;
`

const SubTotalLabel = styled.div`
    font-size: 20px;
    background-color: ${green};
    color: white;
    padding: 15px 30px;
`

const SubTotalDevise = styled.div`
    font-size: 20px;
    color: #5c5c5c;
    padding: 15px 30px;
`

const Unit = styled.span`
    font-size: 70%;
`

const SubTotal = ({ pathInDataState }) => {

    const options = useSelector(({ data }) => {
        const result = getInState(data, pathInDataState);
        return { ...result };
    });

    const performanceData = useMemo(() => getInDataState('offre-performance'), []);

    const subTotalValue = useMemo(() => {
        const quantities = getInDataState('offre-performance.content.quantities.options-included') ?? {};
        const quantitiesKeys =  Object.keys(quantities);

        return Object.entries(options)
            .filter(([_, checked]) => checked)
            .map(([name, _]) => {
                const hasNameKey =  quantitiesKeys.includes(name);
                const quantity = hasNameKey ? quantities[name] : 1;
                const tarif = getInTarifsState(name);

                return quantity * resolvePrice(tarif, performanceData);
            })
            .reduce((acc, current) => !Number.isInteger(current) ? acc : acc + current, 0);
    }, [performanceData, options]);

    return (
        <SubTotalContainer>
            <SubTotalLabel>SOUS-TOTAL</SubTotalLabel>
            <SubTotalDevise>{subTotalValue} € <Unit> HT / MOIS</Unit></SubTotalDevise>
        </SubTotalContainer>
    )
}

const PerformanceOptionsAdd = ({ options, ...props}) => {
    const { displayTotal, pricesDependOn, pathInDataState } = props;

    return (
        <Container>
            {
                options.map((option, index) => (
                    <React.Fragment key={index}>
                        {
                            'checkable' === option.type && (
                                <CheckableOption
                                    dependsOn={pricesDependOn}
                                    pathInDataState={pathInDataState}
                                    pathInDataStateQuantity={option.pathInDataStateQuantity}
                                    name={option.name}
                                    image={option.image}
                                    label={option.label}
                                    tarifsName={option.name}
                                    counterLabel={option.counterLabel}
                                    counterDependsOn={option.counterDependsOn}
                                    full={option.full}
                                    fullOptions={option.fullOptions}
                                    setItselfFalse={option.setItselfFalse}
                                    setInDataState={option.setInDataState}
                                    default={option.default}
                                    disabled={option.disabled}
                                />
                            )
                        }
                        {
                            'group' === option.type && (
                                <PerformanceOptionGroup
                                    dependsOn={pricesDependOn}
                                    partOfId={pricesDependOn}
                                    pathInDataState={pathInDataState}
                                    {...option}
                                />
                            )
                        }
                        {
                            'withChoice' === option.type && (
                                <WithCounterChoiceOption
                                    hektorOffer={pricesDependOn ?? option.pricesDependOn}
                                    partOfId={pricesDependOn}
                                    pathInDataState={pathInDataState}
                                    {...option}
                                />
                            )
                        }
                    </React.Fragment>
                ))
            }
            { displayTotal && <SubTotal pathInDataState={pathInDataState} /> }
        </Container>
    );
}

export default PerformanceOptionsAdd;
