import React, {useCallback, useEffect} from 'react';
import styled from 'styled-components';
import PriceElement from '../PriceElement';
import Counter from '../Counter';
import PromoTag from '../PromoTag';
import PromoFnaimTag from '../PromoFnaimTag';
import personnalisationHektor from '../../Images/HK_offert.png';
import logoHektor from '../../Images/logo-hektor.png';
import {getLabel} from '../../Utils/labels';
import {manualDependsOnTarifsInfo} from '../../Utils/jsonInterpreter';
import {
    SubscriptionContainer,
    SubscriptionStructure,
    SubscriptionInsteadOf,
    SubscriptionLogo,
    SubscriptionOfferName,
    SubscriptionCommissioning
} from '../../Utils/styleConstants';
import {
    setInDataState,
    getInTarifsState,
    getInDataState,
    setFinalPriceInTarifsState,
    resetFinalPriceHektorOptions,
} from '../../Utils/storeHelpers';
import {isPureObject} from "../../Utils/object";

const Image = styled.img`
    padding: 0 5% 0 0;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    height: 100%;
`;

const LogoStructure = styled.div`
    display: flex;
    flex-direction: column;
`;

const AdditionalElementStructure = styled.div`
    display: flex;
    align-items: center;
`;

const Commissioning = styled.p`
    order: -1;
    white-space: nowrap;
`;



function Abonnement({
    pathInDataState,
    tarif,
    offer,
    commissioningName,
    offerName,
    additionalUsers,
    additionalMandataires,
    promoTag,
    promoFnaimTag
}) {
    const tarifsInfo = manualDependsOnTarifsInfo(getInTarifsState(tarif), offer);
    // const hektorMixte = inHektorOfferMixte();

    useEffect(() => {
        if (commissioningName) {
            // let commissioning = !hektorMixte;
            setInDataState(`${pathInDataState}.${commissioningName}`, true);
        }
    }, [pathInDataState, commissioningName])

    const handleUserCounterChange = useCallback(() => {
        setFinalPriceInTarifsState(additionalUsers, null, undefined, offer);
    }, [additionalUsers, offer]);

    const handleMandCounterChange = useCallback(() => {
        setFinalPriceInTarifsState(additionalMandataires, null, undefined, offer);
        resetFinalPriceHektorOptions(true, offer);
    }, [additionalMandataires, offer]);

    return (
        <SubscriptionContainer>
            <Image src={personnalisationHektor} alt='Personnalisation offerte'/>
            <Content>
                <LogoStructure>
                    <SubscriptionLogo src={logoHektor} alt="Logo Hektor"/>
                    <SubscriptionOfferName>{offerName}</SubscriptionOfferName>
                </LogoStructure>
                {
                    offer && tarifsInfo && (
                        <SubscriptionStructure>
                            <PriceElement
                                tarifsName={tarif}
                                dependsOn={offer}
                                fontSize='6em'
                            />
                            {
                                tarifsInfo.insteadOf && !isPureObject(tarifsInfo.insteadOf) && (
                                    <SubscriptionInsteadOf>{`Au lieu de ${tarifsInfo.insteadOf} € ${tarifsInfo.priceUnit}`}</SubscriptionInsteadOf>
                                )
                            }
                            {
                                tarifsInfo.nbrOfUsers && !isPureObject(tarifsInfo.nbrOfUsers) && (
                                    <p>{`Pour ${tarifsInfo.nbrOfUsers } ${tarifsInfo.nbrOfUsers > 1  ? 'utilisateurs' : 'utilisateur'} `}</p>
                                )
                            }
                        </SubscriptionStructure>
                    )
                }
                {
                    additionalUsers && (
                        <AdditionalElementStructure>
                            <p>Utilisateur(s) supplémentaire(s)</p>
                            <Counter
                                masterId={`${pathInDataState}.${additionalUsers}`}
                                initialValue={getInDataState(`${pathInDataState}.${additionalUsers}`) ?? 0}
                                margin='0 45px'
                                callback={handleUserCounterChange}
                            />
                            <PriceElement
                                tarifsName={additionalUsers}
                                dependsOn={offer}
                            />
                        </AdditionalElementStructure>
                    )
                }
                {
                    additionalMandataires && (
                        <AdditionalElementStructure>
                            <p>Nombre de mandataire(s)</p>
                            <Counter
                                masterId={`${pathInDataState}.${additionalMandataires}`}
                                initialValue={getInDataState(`${pathInDataState}.${additionalMandataires}`) ?? 1}
                                margin='0 45px'
                                callback={handleMandCounterChange}
                                min={1}
                            />
                            <PriceElement
                                tarifsName={additionalMandataires}
                                dependsOn={offer}
                            />
                        </AdditionalElementStructure>
                    )
                }
                {
                    commissioningName && (
                        <SubscriptionCommissioning>
                            <Commissioning>{getLabel(commissioningName)}</Commissioning>
                            <PriceElement
                                tarifsName={commissioningName}
                                dependsOn={offer}
                            />
                        </SubscriptionCommissioning>
                    )
                }
            </Content>
            {promoTag && <PromoTag tarifsName={`${offer}-promotion`} promotionId={offer} {...promoTag}/>}
            {promoFnaimTag && <PromoFnaimTag {...promoFnaimTag} />}
        </SubscriptionContainer>
    );
};

export default Abonnement;
