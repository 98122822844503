import {
    getInDataState,
    getInTarifsState,
    inHektorOfferWithAgencies,
    isNewHektorOfferSelected,
    resolveHektorOfferPath
} from './storeHelpers';
import {is} from "date-fns/locale";

const getLabelFrom = labels => value => (labels && labels[value] !== undefined) ? labels[value] : ''
const replaceLabel = label => replaces => Object.entries(replaces).reduce((acc, [k, v]) => acc.replace(`$${k}`, v), label)

/**
 * @function
 * @param {string} name 
 * @param {Object} options
 * 
 * @returns 
 */
export const getLabel = (name, { prefix } = { prefix: ''}) => {
    return prefix !== '' ? getLabelWithObject({labelName: `${prefix}/${name}`}) : getLabelWithObject({labelName: name});
}

const getStringFromArray = arrayToThreat => arrayToThreat.map(o => (o.value !== undefined) ? o.value : o).join(', ')
const getLabelWithObject = ({name, value, labelWith, labelName, order, addOrder, dynValues}) => {
    const labelsFromStorage = localStorage.getItem('labels') ;
    const getLabel = getLabelFrom(JSON.parse(labelsFromStorage === 'undefined' ? '{}' : labelsFromStorage));
    if (labelName !== undefined) {
        name = labelName;
    }
    const valueLabel = getLabel(value);
    if (Array.isArray(value)) {
        return getStringFromArray(value);
    }
    if (labelWith === 'name' || valueLabel === '') {
        const trueLabel = getLabel(name);
        if (trueLabel !== false) {
            const intValue = value === undefined ? 0 : value
            const newOrder = addOrder ? order + addOrder : order
            const replaces = Array.isArray(dynValues) ? dynValues.reduce((o, {key, value}) => {
                o[key.slice(1)] = value;
                return o;
            }, {}) : {};

            return replaceLabel(trueLabel)({...replaces, value: intValue, order: newOrder});
        }
    }

    return valueLabel;
};

function dynConversion(dyn, parentId) {
    const offreSiteMode = getInDataState(`offre-site.mode`);
    let offreKey = offreSiteMode && `offre-site-${offreSiteMode}`;
    let hektorOfferPath = resolveHektorOfferPath();

    switch (dyn) {
        case '$dyn::offre':
            return offreKey ? getLabel(getInDataState(`offre-site.${offreKey}`)) : '';
        case'$dyn::modele':
            const offre = offreKey && getInDataState(`offre-site.${offreKey}`);
            const modelKey = offre && `modele-${offre}`;

            return modelKey ? getLabel(getInDataState(`offre-modele.${modelKey}`)) : '';
        case '$dyn::hektor-offer':
            return getLabel(getInDataState(`${hektorOfferPath}.offre`));
        case '$dyn::promotion':
            return getPromotionLabelUnderParent(parentId);
        case '$dyn::additional-users':
            const hektorOffer = getInDataState(`${hektorOfferPath}.offre`);

            if (isNewHektorOfferSelected()) {
                const tarif = getInTarifsState('hektor-new-subscription');
                return tarif.nbrOfUsers[hektorOffer] ?? 1;
            }

            const offerType = getInDataState(`${hektorOfferPath}.${hektorOffer}.type`);
            const tarif = getInTarifsState(offerType);

            return tarif.nbrOfUsers[hektorOffer] ?? 3;
        case '$dyn::modelo-promotion':
            return '12 premiers mois';
        default:
            return dyn;
    }
};

function getPromotionLabelUnderParent(parentId) {
    let label = '$dyn::promotion';

    if (
        [
            'parent-hektor-creation-agence',
        ].includes(parentId)
    ) {
        label = '6 premiers mois';
    }

    if (['parent-hektor-new-creation-agence']) {
        label = '12 premiers mois';
    }

    if (
        [
            'parent-hektor-fnaim',
            'parent-hektor-fnaim-one',
            'parent-hektor-fnaim-reseau-agences',
            'parent-hektor-fnaim-reseau-mandataires',
            'parent-hektor-fnaim-creation-agence',
            'parent-hektor-fnaim-one-creation-agence',
            'parent-hektor-fnaim-creation-reseau-agences',
            'parent-hektor-fnaim-creation-reseau-mandataires',
            'parent-hektor-new-fnaim',
            'parent-hektor-new-fnaim-reseau-agences',
            'parent-hektor-new-fnaim-reseau-mandataires',
            'parent-hektor-new-fnaim-creation-agence',
            'parent-hektor-new-fnaim-creation-reseau-agences',
            'parent-hektor-new-fnaim-creation-reseau-mandataires'
        ].includes(parentId)
    ) {
        label = '12 premiers mois';
    }

    return label;
}

export function beautifulGetLabel(value, parentId) {
    let finalLabel = '';

    if (!isAlreadyLabel(value)) {
        if (Array.isArray(value)) {
            const values = [];

            value.forEach(item => values.push(beautifulGetLabel(item)));
            finalLabel = values.join(', ');
        } else {
            const labelValues = getLabelFrom(JSON.parse(localStorage.getItem('labels')))(value);
            const matches = labelValues.match(/\$dyn::([a-z-]+)/g);
            let label = labelValues;

            matches && matches.forEach(dyn => label = label.replace(dyn, dynConversion(dyn, parentId)));
            finalLabel = label;
        }
    } else {
        finalLabel = value;
    }

    return finalLabel.replaceAll('*', '');
};

export default getLabelWithObject;

export function getAgencyName(agency) {
    return agency.replace('agency', 'Agence ');
}

export function isAlreadyLabel(value) {
    const labels = Object.values(JSON.parse(localStorage.getItem('labels')));

    return labels.includes(value);
};

export function getCounterInfo(type, finalPrice, parentId) {
    let quantity = null;
    let label = '';
    let offer = parentId ? parentId.replace('parent-', '') : undefined;

    if (isNewHektorOfferSelected()) {
        offer = parentId ? offer.replace('-new', '') : '';
    }

    let child = finalPrice.id ? finalPrice.id : (finalPrice.name ? finalPrice.name : finalPrice.label)
    child = child.includes('recap/') ? child.replace('recap/', '') : child;
    child = child.includes('|') ? child.split('|')[0] : child;

    let hektorOfferPath = resolveHektorOfferPath();

    if ('prefix' === type) {
        switch (child) {
            case 'hektor-additional-users':
            case 'hektor-new-additional-users':
            case 'hektor-additional-users-fnaim':
            case 'hektor-new-additional-users-fnaim':
            case 'hektor-additional-users-fnaim-promotion':
            case 'hektor-new-additional-users-fnaim-promotion':
                child = child.replace('-promotion', '');
                quantity = getInDataState(`${hektorOfferPath}.${offer}.${child}`);
                label =  `${quantity} `;

                break;
            case 'hektor-additional-admins':
            case 'hektor-new-additional-admins':
                quantity = getInDataState(`${hektorOfferPath}.${offer}.additional-admins`);
                label =  `${quantity} `;

                break;
            case 'hektor-additional-admins-fnaim':
            case 'hektor-new-additional-admins-fnaim':
                quantity = getInDataState(`${hektorOfferPath}.${offer}.additional-admins-fnaim`);
                label = `${quantity} `;

                break;
            case 'email-exchange':
                quantity = getInDataState(`emails.quantities`);
                label =  `${quantity} `;

                break;
            case 'option-hektor-e-learning-1':
            case 'option-hektor-e-learning-3':
            case 'option-hektor-e-learning-2-3':
            case 'option-hektor-e-learning-4-6':
            case 'option-hektor-e-learning-sup':
                quantity = getInDataState(`${hektorOfferPath}.${offer}.quantities.${child}`);
                label = `${quantity} `;

                break;
            case 'virtual-visit-equip-agence':
            case 'virtual-visit-equip-agence-premium':
            case 'virtual-visit-equip-mandataire':
            case 'virtual-visit-equip-mandataire-premium':
            case 'virtual-visit-equip2-agence':
            case 'virtual-visit-equip2-agence-premium':
            case 'virtual-visit-equip2-mandataire':
            case 'virtual-visit-equip2-mandataire-premium':
                quantity = getInDataState(child.replace('virtual-visit-', 'virtual-visit.counter-'));
                label = `${quantity} `;

                break;
            case 'mooveo-users-agence':
            case 'mooveo-users-agence-premium':
            case 'mooveo-users-mandataire':
            case 'mooveo-users-mandataire-premium':
                quantity = getInDataState(child.replace('mooveo-', 'mooveo.counter-'));
                label = `${quantity} `;

                break;
            case 'stabi-mooveo-agence':
            case 'stabi-mooveo-agence-premium':
            case 'stabi-mooveo-mandataire':
            case 'stabi-mooveo-mandataire-premium':
                quantity = getInDataState(child.replace('stabi-mooveo-', 'mooveo.counter-stabilisateur-'));
                label = `${quantity} `;

                break;
            case 'pixhdr-users-agence':
            case 'pixhdr-users-mandataire':
                quantity = getInDataState(child.replace('pixhdr-', 'pixhdr.counter-'));
                label = `${quantity} `;

                break;
            case 'option-previsite-full-access-additional-agencies':
            case 'option-previsite-full-access-additional-mandataires':
            case 'option-previsite-full-access-commissioning':
            case 'option-previsite-full-access-mooveo-stab':
            case 'option-previsite-full-access-kit-mobile':
            case 'option-previsite-full-access-pack-vr':
            case 'option-previsite-full-access-mooveo-add-users':
            case 'option-previsite-full-access-smartpix-add-users':
            case 'option-previsite-full-access-socialconnect':
            case 'option-previsite-full-access-spotvideo':
                quantity = getInDataState(`offre-previsite.full-access.quantities.${child}`);
                label = `${quantity} `;

                break;

            case 'option-previsite-full-access-plus-additional-agencies':
            case 'option-previsite-full-access-plus-additional-mandataires':
            case 'option-previsite-full-access-plus-commissioning':
            case 'option-previsite-full-access-plus-mooveo-stab':
            case 'option-previsite-full-access-plus-kit-mobile':
            case 'option-previsite-full-access-plus-pack-vr':
            case 'option-previsite-full-access-plus-mooveo-add-users':
            case 'option-previsite-full-access-plus-smartpix-add-users':
            case 'option-previsite-full-access-plus-socialconnect':
            case 'option-previsite-full-access-plus-spotvideo':
                quantity = getInDataState(`offre-previsite.full-access-plus.quantities.${child}`);
                label = `${quantity} `;

                break;

            case 'option-previsite-pro-additional-agence':
            case 'option-previsite-pro-additional-agencies':
            case 'option-previsite-pro-kit-mobile':
            case 'option-previsite-pro-mooveo-stab':
            case 'option-previsite-pro-pack-vr':
            case 'option-previsite-pro-additional-mandataires':
                quantity = getInDataState(`offre-previsite.pro.quantities.${child}`);
                label = `${quantity} `;

                break;

            case 'option-previsite-pro-commissioning':
                type = getInDataState(`offre-previsite.pro.type`);

                if ( type === "previsite-mandataire") {
                    quantity = getInDataState(`offre-previsite.pro.quantities.${child}`);
                    label = `${quantity} `;
                }

                break;

            case 'option-previsite-smartpix-mooveo-commissioning':
            case 'option-previsite-smartpix-mooveo-mooveo-stab':
            case 'option-previsite-smartpix-mooveo-kit-mobile':
            case 'option-previsite-smartpix-mooveo-pack-vr':
            case 'option-previsite-smartpix-mooveo-add-users':
                quantity = getInDataState(`offre-previsite.smartpix-mooveo.quantities.${child}`);
                label = `${quantity} `;

                break;
            case 'option-previsite-smartpix-virtualvisit-commissioning':
            case 'option-previsite-smartpix-virtualvisit-mooveo-stab':
            case 'option-previsite-smartpix-virtualvisit-kit-mobile':
            case 'option-previsite-smartpix-virtualvisit-pack-vr':
            case 'option-previsite-smartpix-virtualvisit-add-users':
                quantity = getInDataState(`offre-previsite.smartpix-virtualvisit.quantities.${child}`);
                label = `${quantity} `;

                break;
            case 'option-previsite-community-management-additional-agencies':
            case 'option-previsite-community-management-commissioning':
                quantity = getInDataState(`offre-previsite.community-management.quantities.${child}`);
                label = `${quantity} `;

                break;
            default:
                break;
        }
    } else if ('suffix' === type) {
        if (finalPrice.data) {
            label = ' - ' + finalPrice.data;
        } else {
            switch (child) {
                case 'hektor-additional-mandataires':
                case 'hektor-new-additional-mandataires':
                case 'hektor-additional-mandataires-fnaim':
                case 'hektor-new-additional-mandataires-fnaim':
                case 'hektor-additional-mandataires-fnaim-promotion':
                case 'hektor-new-additional-mandataires-fnaim-promotion':
                    child = child.replace('-promotion', '');
                    label = ' : ' + getInDataState(`${hektorOfferPath}.${offer}.${child}`);

                    break;
                case 'option-hektor-e-learning':
                case 'option-hektor-new-e-learning':
                    quantity = getInDataState(`${hektorOfferPath}.${offer}.quantities.option-hektor-e-learning`);

                    if (quantity) {
                        let userLabel = 'utilisateur';

                        if (inHektorOfferWithAgencies(offer)) {
                            userLabel = 'agence';
                        }

                        label = ' - ' + quantity + (quantity > 1 ? ` ${userLabel}s` : ` ${userLabel}`);
                    }

                    break;
                case 'virtual-visit-agence':
                case 'virtual-visit-agence-premium':
                case 'virtual-visit-mandataire':
                case 'virtual-visit-mandataire-premium':
                    quantity = getInDataState(child.replace('virtual-visit-', 'virtual-visit.counter-'));
                    label = ' - ' + quantity + (quantity > 1 ? ' comptes' : ' compte');

                    break;
                case 'first-agence':
                case 'connect-agence':
                case 'premium-agence':
                case 'first-mandataire':
                case 'connect-mandataire':
                case 'premium-mandataire':
                    quantity = getInDataState(`spotvideo.counter-${child}`);
                    label = ' - ' + quantity + (quantity > 1 ? ' comptes' : ' compte');

                    break;

                case 'mooveo-agence':
                case 'mooveo-agence-premium':
                case 'mooveo-mandataire':
                case 'mooveo-mandataire-premium':
                    quantity = getInDataState(`mooveo.counter-${child}`);
                    label = ' - ' + quantity + (quantity > 1 ? ' comptes' : ' compte');

                    break;

                case 'mes-mooveo-agence':
                case 'mes-mooveo-agence-premium':
                case 'mes-mooveo-mandataire':
                case 'mes-mooveo-mandataire-premium':
                    quantity = getInDataState(child.replace('mes-', 'mooveo.counter-'));
                    label = ' - ' + quantity + (quantity > 1 ? ' comptes' : ' compte');

                    break;

                case 'pixhdr-agence':
                case 'pixhdr-mandataire':
                    quantity = getInDataState(`pixhdr.counter-${child}`);
                    label = ' - ' + quantity + (quantity > 1 ? ' comptes' : ' compte');

                    break;
                case 'socialconnect-agence':
                case 'socialconnect-mandataire':
                    quantity = getInDataState(`socialconnect.counter-${child}`);
                    label = ' - ' + quantity + (quantity > 1 ? ' comptes' : ' compte');

                    break;

                case 'newsfactory-agence':
                    quantity = getInDataState(`newsfactory.counter-${child}`);
                    label = ' - ' + quantity + (quantity > 1 ? ' comptes' : ' compte');

                    break;
                case 'mes-newsfactory-agence':
                    quantity = getInDataState(child.replace('mes-', 'newsfactory.counter-'));
                    label = ' - ' + quantity + (quantity > 1 ? ' comptes' : ' compte');

                    break;
                default:
                    break;
            }
        }
    }

    return label;
};
