import React from 'react';
import styled, { css } from 'styled-components';
import {green, grey, lightGrey} from "../../Utils/styleConstants";
import {getInDataState, getInTarifsState} from "../../Utils/storeHelpers";
import {resolvePrice} from "../../Utils/lePack/tarif";
import {OFFER_ONE} from "../../Utils/lePack/constant";

const StyledContainer = styled.div`
  padding: 1em;
  
  * { color: inherit; }
`;

const Text = styled.span`
  color: ${({ color }) => color || 'initial' }
`

const StyledTarifsContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  box-shadow: rgb(0 0 0 / 24%) 0 3px 8px;
  overflow: hidden;
`

const TarifContainer = styled.div`
  display: flex;
  letter-spacing: 0.03em;
  background-color: ${({ backgroundColor }) => backgroundColor || lightGrey };
  color: ${({ color }) => color || grey };
  font-size: ${({ fontSize }) => fontSize || '23px' };
  font-weight: bold;
  text-shadow: 0.5px 0 currentcolor;
  
  &:not(:last-child) {
    border-bottom: 1px solid #e1e1e1;
  }

  & > div {
    padding: 1.5em 2em;
  }
`

const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.3em;
  width: 45%;

`

const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.3em;
  text-align: end;
  width: 55%;
`

const Label = styled.div`
  text-transform: uppercase;
  font-weight: bold;
`
const Price = styled.div`
  text-transform: uppercase;
`

const UserPriceLabel = styled.div`
  font-style: italic;
  font-size: 70%;
  font-weight: normal;
  text-shadow: none;
`

const UserPrice = styled.div`
  text-transform: uppercase;
  font-size: 70%;
`

const lineThroughBefore = css`
     &::before {
        position: absolute;
        content: "";
        left: 0;
        top: 45%;
        right: 0;
        border-top: 3px solid;
        border-color: ${green};
        transform: rotate(-25deg);
    }
`

const Devise = styled.span`
  color: ${({ color }) => color || 'inherit' };
`

const Value = styled.span`
  position: relative;
  
  ${({ lineThrough }) => lineThrough && lineThroughBefore};
`

const Unit = styled.span`
  font-size: 70%;
`

const LePackRecap = () => {
    const lePackData = getInDataState('offre-lepack');

    const lePackValue = getInTarifsState('lepack-value-display');
    const lePackTarif = getInTarifsState('lepack-tarif-display');
    const lePackIntroductoryOffer = getInTarifsState('lepack-introductory-offer');

    const lePackValueAdditionalUser = getInTarifsState('lepack-value-additional-user-display');
    const lePackTarifAdditionalUser = getInTarifsState('lepack-tarif-additional-user-display');

    const lePackAdditionalUser = getInTarifsState('lepack-additional-users');

    return (
        <StyledContainer>
           <StyledTarifsContainer>
                <TarifContainer>
                    <LabelContainer>
                        <Label>Valeur Interkab Solutions</Label>
                            <UserPriceLabel>Utilisateur(s) supplémentaire(s)</UserPriceLabel>
                    </LabelContainer>
                    <PriceContainer>
                        <Price>
                            <Devise>
                                <Value lineThrough={true}>{resolvePrice(lePackValue, lePackData)}</Value> €
                            </Devise>
                            <Unit> HT {lePackValue.priceUnit}</Unit>
                        </Price>
                            <UserPrice>
                                <Devise>
                                    <Value>{resolvePrice(lePackValueAdditionalUser, lePackData)}</Value> €
                                </Devise>
                                <Unit> HT {lePackValueAdditionalUser.priceUnit}</Unit>
                            </UserPrice>
                    </PriceContainer>
                </TarifContainer>
               <TarifContainer>
                   <LabelContainer>
                       <Label> Tarif <Text color={green}>Interkab Solutions</Text></Label>
                           <UserPriceLabel>Utilisateur(s) supplémentaire(s)</UserPriceLabel>
                    </LabelContainer>
                   <PriceContainer>
                       <Price>
                           <Devise color={green}>
                               <Value>{resolvePrice(lePackTarif, lePackData)}</Value> €
                           </Devise>
                           <Unit> HT {lePackTarif.priceUnit}</Unit>
                       </Price>
                           <UserPrice>
                               <Devise color={green}>
                                   <Value>{resolvePrice(lePackTarifAdditionalUser, lePackData)}</Value> €
                               </Devise>
                               <Unit> HT {lePackTarifAdditionalUser.priceUnit}</Unit>
                           </UserPrice>
                   </PriceContainer>
               </TarifContainer>
               <TarifContainer backgroundColor={green} color='black' fontSize='25px'>
                   <LabelContainer>
                       <Label>Objectif <Text color="white">2000</Text></Label>
                           <UserPriceLabel>Utilisateur(s) supplémentaire(s)</UserPriceLabel>
                   </LabelContainer>
                   <PriceContainer>
                       <Price>
                           <Devise color="white">
                               <Value>{resolvePrice(lePackIntroductoryOffer, lePackData)}</Value> €
                           </Devise>
                           <Unit> HT {lePackIntroductoryOffer.priceUnit}</Unit>
                       </Price>
                           <UserPrice>
                               <Devise color="white">
                                   <Value>{resolvePrice(lePackAdditionalUser, lePackData)}</Value> €
                               </Devise>
                               <Unit> HT {lePackAdditionalUser.priceUnit}</Unit>
                           </UserPrice>
                   </PriceContainer>
               </TarifContainer>
           </StyledTarifsContainer>
        </StyledContainer>
    );
}

export default LePackRecap;
