import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectDataState } from '../Store/selectors';
import styled from 'styled-components';
import {
    setInDataState,
    getInDataState,
    deleteInDataState,
    multipleClearInDataState,
    createKeysInDataState,
    multipleResetFinalPriceOfTarifs,
    multipleClearFinalPriceOfTarifs,
    multipleSetInDataState,
} from '../Utils/storeHelpers';
import checkableComponentFactory from "../Utils/checkableComponentsContainer";

const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    ${props => props.centerOptions && 'justify-content: center;'};
    padding: 10px;
`;

const CheckableGroup = props => {
    const [checked, setChecked] = useState(null);
    const [disabled, setDisabled] = useState([])
    const dataState = useSelector(selectDataState);

    const CheckableComponent = checkableComponentFactory[props.checkType];

    // On component mounting
    useEffect(() => {
        const options = props.options ?? [];

        options.forEach(option => {
            if (option.checkedByDefault) {
                // Set the value of the option in data state as we click on it
                setInDataState(props.pathInDataState, option.value, props.isArrayInDataState);
            }
        })
    }, [props])

    useEffect(() => {
        const options = props.options ?? [];
        const dataInState = getInDataState(props.pathInDataState);

        const checked = options.map(option => {
            const value = option.value;

            if (Array.isArray(dataInState)) {
                return dataInState.includes(value);
            } else if (dataInState === value) {
                return true
            } else if (option.asKey) {
                return dataInState !== undefined && dataInState.hasOwnProperty(value);
            }

            return false;
        })

        const disabled = options.map(option => {
            if (option.disabled) {
                const disabled = option.disabled;
                if (Array.isArray(disabled)) {
                    return disabled.some(condition => {
                        if (condition.startsWith('if:')) {
                            const value = condition.split('if:')[1];
                            return (dataInState ?? []).includes(value);
                        }
                        return true;
                    });
                } else if (typeof disabled === 'string') {
                    if (disabled.startsWith('if:')) {
                        const value = disabled.split('if:')[1];
                        return (dataInState ?? []).includes(value);
                    }
                    return true;
                }
                return option.disabled;
            }
            return false;
        });

        setChecked(checked);
        setDisabled(disabled);
    }, [props, dataState])

    // Callback when user click on an option
    const handleClickOption = useCallback((index, clickedOption) => {
        if (disabled[index]) {
            return;
        }

        if (props.radio && props.isArrayInDataState) {
            const options = props.options ?? [];

            options.forEach(option => {
                clickedOption.value === option.value && !checked[index]
                    ? setInDataState(
                        props.pathInDataState,
                        option.value,
                        props.isArrayInDataState,
                        option.asKey,
                        option.forArray
                    )
                    : deleteInDataState(props.pathInDataState, option.value, option.asKey);


            });
        } else {
            !checked[index]
                ? setInDataState(
                    props.pathInDataState,
                    clickedOption.value,
                    props.isArrayInDataState,
                    clickedOption.asKey,
                    clickedOption.forArray
                )
                : deleteInDataState(props.pathInDataState, clickedOption.value, clickedOption.asKey);
        }

        // Clear some data (if clearOnChangeInDataState paths indicated in json file)
        multipleClearInDataState(props.clearOnChangeInDataState);

        if (clickedOption.clearOnChangeInDataState) {
            multipleClearInDataState(clickedOption.clearOnChangeInDataState);
        }

        // Create keys (if createKeysInDataState indicated in json file for this option)
        createKeysInDataState(clickedOption.createKeysInDataState);

        multipleSetInDataState(clickedOption.setInDataState);

        // Reset finalPrice (if resetFinalPrice indicated in json file for this option)
        multipleResetFinalPriceOfTarifs(clickedOption.resetFinalPrice);

        // Clear some finalPrice of tarifs (indicated in json file)
        multipleClearFinalPriceOfTarifs(props.clearFinalPrice, undefined, props.hektorOffer);
    }, [props, checked]);

    return (
        checked && (
            <Container centerOptions={props.centerOptions}>
                {
                    props.options.map((option, index) => (
                        <CheckableComponent
                            key={index}
                            index={index}
                            perLine={props.perLine}
                            hektorOffer={props.hektorOffer}
                            masterId={props.masterId}
                            name={props.name}
                            checked={checked[index]}
                            disabled={disabled[index]}
                            onClick={handleClickOption}
                            option={option}
                        />
                    ))
                }
            </Container>
        )
    )
}

export default CheckableGroup;
