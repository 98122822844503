import {connect } from 'react-redux'
import Menu from '../Components/Menu'
import { mapStateToPropsAddable } from '../Utils/mapToProps'
import {getInState} from "../Utils/storeHelpers";

/**
 * @param {Object} dataState
 * @return {(function(*, *): string)|*}
 */
const createMenuTitleFn = (dataState) => {
    // We get ids in _order state of saleOutlets for both lepack and performance
    const lepackSalesOutletIds = getInState(dataState._order, 'lepack-point-de-vente|point-de-vente') || [];
    const performanceSalesOutletIds = getInState(dataState._order, 'performance-point-de-vente|point-de-vente') || [];

    // combine both arrays (if one is undefined, we'll use the other)
    const salesOutletIds = [...lepackSalesOutletIds, ...performanceSalesOutletIds];

    // create the actual menuTitle display function
    return (id, order) => {
        const salesOutletLabel = `Point de vente`;

        if (order === 1) {
            const agenceInformations = [
                getInState(dataState.agence, 'adresse'),
                getInState(dataState.agence, 'cp'),
            ];

            return `${salesOutletLabel} principal : ${agenceInformations.join(', ')}`;
        }

        const saleOutletId = salesOutletIds[order-2];
        // Check which product type this sales outlet belongs to
        const isLepack = lepackSalesOutletIds.includes(saleOutletId);
        const productPrefix = isLepack ? 'lepack' : 'performance';

        const address = getInState(dataState, `${productPrefix}-point-de-vente.point-de-vente|${saleOutletId}/address`);
        const postalCode = getInState(
            dataState, `${productPrefix}-point-de-vente.point-de-vente|${saleOutletId}/postalCode`
        );

        return `${salesOutletLabel} supplémentaire ${order-1} : ${address}, ${postalCode}`;
    }
};

// get elements formatted as 'postalCodeX'
const gridElements = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J']
    .map(x => `postalCode${x}`);

const mapStateToProps = (state, ownProps) => {
    // Check if either lepack or performance subscription is active
    const isLepackSalesOutletSubscriptionActive =
        getInState(state.data, 'offre-lepack.content.abonnement-point-de-vente') ?? false;
    const isPerformanceSalesOutletSubscriptionActive =
        getInState(state.data, 'offre-performance.content.abonnement-point-de-vente') ?? false;

    // Get sale outlet IDs for both lepack and performance
    const lepackSalesOutletsIds = getInState(state.data, '_order.lepack-point-de-vente|point-de-vente') ?? [];
    const performanceSalesOutletsIds = getInState(state.data, '_order.performance-point-de-vente|point-de-vente') ?? [];

    // Calculate total sales outlet count based on active subscriptions
    const lepackCount = isLepackSalesOutletSubscriptionActive ? lepackSalesOutletsIds.length : 0;
    const performanceCount = isPerformanceSalesOutletSubscriptionActive ? performanceSalesOutletsIds.length : 0;
    const salesOutletCount = lepackCount + performanceCount;

    /**
     * Create all grid element that compose the postal codes Menu from the gridElements declared below
     *
     * @param {string} id
     * @return {Object}
     */
    const refs = (id) => {
        return Array.from(Array(10), (_, index) => index)
            .map(index => {
                const postalCodeAttribut = gridElements[index];

                return {
                    [postalCodeAttribut]: {
                        type: 'InputTextContainer',
                        name: `${id}/${postalCodeAttribut}`,
                        placeholder: `Code postal ${index + 1}`,
                    }
                }
            })
            .reduce((acc, current) => {
                return {...acc, ...current};
            }, {});
    }

    return {
        ...mapStateToPropsAddable(state, { ...ownProps, min: salesOutletCount + 1}),
        deletable: false,
        gridElements: gridElements,
        gridRepeat: 5,
        max: salesOutletCount + 1,
        refs: refs,
        menuTitle: createMenuTitleFn(state.data),
    }
}

const styleOverride = {
    menuElement: {
        rowGap: '25px',
        marginTop: '10px',
        borderRadius: '10px',
        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
    },
    containerMenuElement: (id, order) => ({
        marginTop: order > 1 ? '25px' : '15px'
    })
};

const PigimmoPostalCodes = (props) => {
    return (
        <Menu styleOverride={styleOverride} {...props} />
    )
}

export default connect(
    mapStateToProps
)(PigimmoPostalCodes)