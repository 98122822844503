import React, { useCallback } from 'react';
import styled from 'styled-components';
import PriceElement from '../PriceElement';
import Counter from '../Counter';
import personnalisationHektor from '../../Images/HK_offert.png';
import performanceHektor from '../../Images/logo-performance.png';
import {
    SubscriptionContainer,
    SubscriptionStructure,
    SubscriptionLogo, SubscriptionCommissioning,
} from '../../Utils/styleConstants';
import {
    getInDataState,
    setFinalPriceInTarifsState,
} from '../../Utils/storeHelpers';
import {getTarifsInfoByTarifsName} from "../../Utils/jsonInterpreter";
import {resolveNbrOfUsers} from "../../Utils/lePack/tarif";
import {getLabel} from "../../Utils/labels";

const Image = styled.img`
    padding: 0 5% 3vh 3%;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    height: 100%;
    padding: 3vh 0;
`;

const LogoStructure = styled.div`
    display: flex;
    flex-direction: column;
`;

const AdditionalElementStructure = styled.div`
    display: flex;
    align-items: center;
    padding: 2vh 0 0;
`;

const Commissioning = styled.p`
    order: -1;
    white-space: nowrap;
`;

const PerformanceSubscription = props => {
    const { subscriptionTarifName, additionalUserTarif, commissioningPerformance } = props
    const performanceData = getInDataState('offre-performance');

    const tarifsInfo = getTarifsInfoByTarifsName(subscriptionTarifName.name);

    const defaultUsersCount = resolveNbrOfUsers(tarifsInfo, performanceData) || 0;

    // Callback used when the user counter change
    const handleUserCounterChange = useCallback(() => {
        // Whenever the user change the counter of additionalUsers, we reset its finalPrice
        setFinalPriceInTarifsState(additionalUserTarif.name, null, undefined, undefined);
    }, [additionalUserTarif]);

    return (
        <SubscriptionContainer>
            <Image src={personnalisationHektor} alt='Personnalisation offerte' />
            <Content>
                <LogoStructure>
                    <SubscriptionLogo src={performanceHektor} alt="Logo Performance" />
                </LogoStructure>
                {subscriptionTarifName &&
                    <SubscriptionStructure>
                        <PriceElement
                            tarifsName={subscriptionTarifName.name}
                            dependsOn={subscriptionTarifName.dependsOn}
                            fontSize='6em'
                        />
                        <p>{`Pour ${defaultUsersCount} ${defaultUsersCount > 1 ? 'utilisateurs' : 'utilisateur'}`}</p>
                    </SubscriptionStructure>
                }
                {additionalUserTarif &&
                    <AdditionalElementStructure>
                        <div>
                            <p>Utilisateur(s) supplémentaire(s)</p>
                            <p>10 signatures et 50 sms inclus</p>
                        </div>
                        <Counter
                            masterId={`${props.pathInDataState}`}
                            initialValue={getInDataState(`${props.pathInDataState}`) ?? 0}
                            margin='0 45px'
                            callback={handleUserCounterChange}
                        />
                        <PriceElement
                            tarifsName={additionalUserTarif.name}
                            dependsOn={additionalUserTarif.dependsOn}
                        />
                    </AdditionalElementStructure>
                }
                <SubscriptionCommissioning>
                    <Commissioning>{getLabel(commissioningPerformance.name)}</Commissioning>
                    <PriceElement
                        tarifsName={commissioningPerformance.name}
                        dependsOn={commissioningPerformance.dependsOn}
                    />
                </SubscriptionCommissioning>

            </Content>
        </SubscriptionContainer>
    );
};

export default PerformanceSubscription;
