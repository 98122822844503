import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import CheckmarkContainer from './Icons/CheckmarkContainer';
import PriceElement from './PriceElement';
import Counter from '../Components/Counter';
import { getImage } from '../Utils/jsonInterpreter';
import { GenericTextInfo, StyledCheckbox } from '../Utils/styleConstants';
import {
    getInDataState,
    setInDataState,
    setFinalPriceInTarifsState,
    getHektorContext,
    inHektorOfferMixte,
    multipleClearFinalPriceOfTarifs,
    resetFinalPriceHektorOptions, isFnaimOffer, isNewHektorOfferSelected
} from '../Utils/storeHelpers';

const Container = styled.div`
    display: flex;
`;

const CheckableBlock = styled(StyledCheckbox)`
    flex-direction: column;
    justify-content: space-between;
    height: 350px;
    width: 48%;
    padding: 20px;
`;

const TitleStructure = styled.div`
    max-width: 50%;
`;

const Title = styled.p`
    font-size: 30px;
    font-weight: 500;
`;

const TitleBold = styled.p`
    font-size: 30px;
    font-weight: bold;
`;

const ImageSuperAdmin = styled.img`
    position: absolute;
    right: 7%;
    bottom: 30%;
    height: 225px;
`;


const ImageAdmin = styled.img`
    position: absolute;
    right: 7%;
    bottom: 30%;
    height: 225px;
`;

const ContentStructure = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 50%;
`;

const Note = styled.p`
    font-size: 0.9em;
`;

const AdditionalAdminStructure = styled.div`
    display: flex;
    align-items: center;
`;

const HektorAccounts = props => {
    const [checkedSuperAdmin, setCheckedSuperAdmin] = useState(null);
    const [checkedAdmin, setCheckedAdmin] = useState(null);

    const hektorMixte = inHektorOfferMixte();
    const hektorContext = getHektorContext();
    const supKeyFnaim = isFnaimOffer(props.hektorOffer) ? '-fnaim' : '';

    const adminTarif = (isNewHektorOfferSelected() ? 'hektor-new-account-admin' : 'hektor-account-admin') + supKeyFnaim;
    const superAdminTarif =  (isNewHektorOfferSelected() ? 'hektor-new-account-super-admin' : 'hektor-account-super-admin') + supKeyFnaim;
    const additionalAdminTarif =  (isNewHektorOfferSelected() ? 'hektor-new-additional-admins' : 'hektor-additional-admins') + supKeyFnaim;

    useEffect(() => {
        null === checkedAdmin && setCheckedAdmin(getInDataState(`${props.id}.admin-account` + supKeyFnaim));
        if (null === checkedSuperAdmin) {
            hektorContext.includes('mandataires') && hektorMixte ? setCheckedSuperAdmin(false) : setCheckedSuperAdmin(true);
        }
    }, [props.id, checkedAdmin, checkedSuperAdmin, hektorContext, hektorMixte, supKeyFnaim]);

    // Callback used when user click on additional admins counter
    const handleCounterClick = useCallback((count, event) => {
        // Do not trigger the parent onClick()
        event.stopPropagation();

        // Whenever we change the counter, reset the finalPrice
        setFinalPriceInTarifsState(additionalAdminTarif, null, undefined, props.hektorOffer);
        // We also reset the finalPrice of hektor option add
        resetFinalPriceHektorOptions(false, props.hektorOffer);
    }, [props.hektorOffer, supKeyFnaim]);

    // useEffect whenever checkedAdmin hook value changes
    useEffect(() => {
        null !== checkedAdmin && setInDataState(`${props.id}.admin-account` + supKeyFnaim, checkedAdmin);
        // Clear some finalPrice of tarifs (indicated in json file)
        multipleClearFinalPriceOfTarifs(props.clearFinalPrice, undefined, props.hektorOffer);
    }, [checkedAdmin, props, supKeyFnaim]);

    // useEffect whenever checkedSuperAdmin hook value changes
    useEffect(() => {
        null !== checkedSuperAdmin && setInDataState(`${props.id}.superadmin-account` + supKeyFnaim, checkedSuperAdmin);
    }, [checkedSuperAdmin, props.id, supKeyFnaim]);

    return (
        hektorContext.includes('mandataires') && hektorMixte ? (
            <GenericTextInfo>Les comptes super admin et admins sont déjà définis (étape 3).</GenericTextInfo>
        ) : (
            null !== checkedSuperAdmin && null !== checkedAdmin && (
                <Container>
                    <CheckableBlock checked={checkedSuperAdmin}>
                        <TitleStructure>
                            Compte
                            <TitleBold>Super</TitleBold>
                            <Title>Admin</Title>
                        </TitleStructure>
                        <ImageSuperAdmin src={getImage('/static/img/hektor/hektor-superadmin.svg')} alt='#' />
                        <ContentStructure>
                            <PriceElement
                                tarifsName={superAdminTarif}
                                dependsOn={props.hektorOffer}
                                fontSize='5em'
                            />
                            <Note>L'administrateur a tous les droits sur l'agence, tandis que le Super Admin a tous les droits sur le réseau.</Note>
                        </ContentStructure>
                        <CheckmarkContainer checked={checkedSuperAdmin} />
                    </CheckableBlock>
                    <CheckableBlock
                        checked={checkedAdmin}
                        onClick={() => setCheckedAdmin(!checkedAdmin)}
                    >
                        <TitleStructure>
                            Compte
                            <TitleBold>Admin</TitleBold>
                        </TitleStructure>
                        <ImageAdmin src={getImage('/static/img/hektor/hektor-admin.svg')} alt='#' />
                        <ContentStructure>
                            <PriceElement
                                tarifsName={adminTarif}
                                dependsOn={props.hektorOffer}
                                fontSize='5em'
                            />
                            {
                                checkedAdmin && (
                                    <AdditionalAdminStructure>
                                        <Note>Compte(s) supplémentaire(s)</Note>
                                        <Counter
                                            masterId={props.id}
                                            name={'additional-admins' + supKeyFnaim}
                                            initialValue={getInDataState(`${props.id}.additional-admins` + supKeyFnaim) ?? 0}
                                            margin='0 30px'
                                            callback={handleCounterClick}
                                        />
                                        <PriceElement
                                            tarifsName={additionalAdminTarif}
                                            dependsOn={props.hektorOffer}
                                        />
                                    </AdditionalAdminStructure>
                                )
                            }
                        </ContentStructure>
                        <CheckmarkContainer checked={checkedAdmin} />
                    </CheckableBlock>
                </Container>
            )
        )
    );
};

export default HektorAccounts;
