import React from 'react';
import BiChoice from './BiChoice';
import MonthSelect from './MonthSelect';
import {getHektorContext, getInDataState, inHektorOfferMixte} from '../Utils/storeHelpers';
import { GenericTextInfo } from '../Utils/styleConstants';
import {getLabel} from "../Utils/labels";

const Prelevement = props => {
    // Use of redux
    const hektorMixte = inHektorOfferMixte();
    const hektorContext = getHektorContext();
    const products = getInDataState('produits');

    const hasHektorProduct = products.includes('hektor');
    const hasSiteProduct = products.includes('site');
    const hasLePackProduct = products.includes('lepack');
    const hasAkademieProduct = products.includes('akademie');
    const hasLbiMediaProduct = products.includes('lbi-media');
    const hasOskarProduct = products.includes('oskar');
    const hasPerformanceProduct = products.includes('performance');

    const show =
            (hasLbiMediaProduct && 'lbi-media' === props.product)
        ||  (hasOskarProduct && 'oskar' === props.product)
        ||  (hasAkademieProduct && 'akademie' === props.product)
        ||  (hasHektorProduct && 'hektor' === props.product)
        ||  (products.includes('hektor-new') && 'hektor-new' === props.product)
        ||  (!hasHektorProduct && hasLePackProduct &&'lepack' === props.product)
        ||  (hasPerformanceProduct && 'performance' === props.product)
        ||  (!hasHektorProduct && !hasLePackProduct && hasSiteProduct && 'site' === props.product)
    ;


    const getPreviousSamplingDate = () => {
        const dayValue = getLabel(getInDataState(props.biChoice.pathInDataState));
        const monthValue = getInDataState(props.monthSelect.pathInDataState[0]);
        const yearValue = getInDataState(props.monthSelect.pathInDataState[1]);

        return `${dayValue} - ${monthValue}/${yearValue}`;
    };

    return (
        show ? (
            hektorContext.includes('agences') && hektorMixte ? (
                <GenericTextInfo>La date du 1er prélèvement sera définie lors du choix des options pour votre réseau mandataires (étape 6).</GenericTextInfo>
            ) : (
                <>
                    <BiChoice
                        pathInDataState={props.biChoice.pathInDataState}
                        margin={props.biChoice.margin}
                        byDefault={props.biChoice.byDefault}
                        options={props.biChoice.options}
                    />
                    <MonthSelect
                        pathInDataState={props.monthSelect.pathInDataState}
                        yearInterval={props.monthSelect.yearInterval}
                    />
                </>
            )
        ) : (
            <GenericTextInfo>
                La date du 1er prélèvement a déjà été définie précédemment ({getPreviousSamplingDate()}).
            </GenericTextInfo>
        )
    );
};

export default Prelevement;
