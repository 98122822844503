import {dispatch} from '../Store/store';
import {getIdsOfFinalPrices, sameArrays} from './jsonInterpreter';
import {
    LOAD_AGENCIES,
    LOAD_CONTENT_PAGE,
    LOAD_CRO_CONTENT_PAGE,
    LOAD_DOMAIN_NAMES,
    LOAD_DOMAINS,
    LOAD_EMAILS,
    LOAD_GATEWAYS,
    LOAD_HEBERGEMENT,
    LOAD_TRADUCTIONS
} from '../Store/actions';

import * as _ from '../Utils/object';

import {
    addInFinalPrices, dynamicPriceCalculation,
    getConfigKey,
    getFullAccessTypeOffer, getHektorNewOffers, getHektorOffer,
    getHektorOffers,
    getHektorTypeFromHektorOffer,
    getInDataState,
    getInTarifsState,
    getNumberOfHektorAdmins,
    getNumberOfHektorAgencies,
    getNumberOfHektorMandataires, getNumberOfHektorNewMandataires,
    getOffers,
    getPrevisiteTypeFromOffer,
    getSiteMode,
    getTarifsState, hektorDependOfNumberOfMandataire,
    inHektorOfferMixte,
    inHektorOfferWithAgencies,
    inHektorOfferWithMandataires, isFnaimOffer,
    isIkaLePackType, isNewHektorOfferSelected, isOldHektorOfferSelected, multipleClearFinalPriceOfTarifs,
    optionWithCounter, resetFinalPrices, resolveHektorOfferPath, resolveHektorParent,
    setFinalPriceInTarifsState
} from './storeHelpers';

import {getLabel} from "./labels";

import {resolveNbrOfUsers, resolvePrice} from './lePack/tarif.js';
import {setInState} from "./reducerHelpers";
import loadScreenOffer from "./recap/lbi-media/loadScreenOffer";
import loadLedOffer from "./recap/lbi-media/loadLedOffer";
import computeOskarPrice from "../Components/computeOskarPrice";
import computeTarifForThresholdDependentMandataires from "../Components/computeTarifForThresholdDependentMandataires";

function loadOldHektorOffer() {
    loadHektorType();
    loadHektorAdmins(
        'admin-account', 'hektor-account-admin',
        'superadmin-account', 'hektor-account-super-admin',
        'additional-admins', 'hektor-additional-admins'
    );
    loadCommissioningHektor('commissioning-hektor');
    loadHektorAdditionalElements('hektor-additional-users');
    loadHektorAdditionalElements('hektor-additional-mandataires');

    if (isFnaimOffer(getHektorOffer())) {
        loadHektorAgencies([
            'hektor-agencies-succursale', 'hektor-agencies-independant', 'hektor-agencies-franchise'
        ], 'hektor-additional-users-fnaim');
    } else {
        loadHektorAgencies([
            'hektor-agencies-succursale', 'hektor-agencies-independant', 'hektor-agencies-franchise'
        ], 'hektor-additional-users');
    }

    loadHektorOptions('options');
    loadHektorOptionsAdd('options-add');
    loadHektorOptionsInc('options-inc');
    loadHektorOptionModelo('options-add');
    loadHektorRecupData('recup-data', ['recup-data', 'recup-data-fnaim']);
    loadHektorAgenciesPromotions();
}

function loadNewHektorOffer() {
    loadHektorSubscription();
    loadHektorAdmins(
        'admin-account', 'hektor-new-account-admin',
        'superadmin-account', 'hektor-new-account-super-admin',
        'additional-admins', 'hektor-new-additional-admins'
    );
    loadCommissioningHektor('commissioning-hektor-new');
    loadHektorAdditionalElements('hektor-new-additional-users');
    loadHektorAdditionalElements('hektor-new-additional-mandataires');

    if (isFnaimOffer(getHektorOffer())) {
        loadHektorAgencies([
            'hektor-new-agencies-succursale',
            'hektor-new-agencies-independant',
            'hektor-new-agencies-franchise'
        ], 'hektor-new-additional-users-fnaim');
    } else {
        loadHektorAgencies([
            'hektor-new-agencies-succursale',
            'hektor-new-agencies-independant',
            'hektor-new-agencies-franchise'
        ], 'hektor-new-additional-users');
    }

    loadHektorOptions('options');
    loadHektorOptionsAdd('options-add');
    loadHektorOptionsInc('options-inc');
    loadHektorOptionModelo('options-add');
    loadHektorRecupData('recup-data', ['hektor-new-recup-data', 'hektor-new-recup-data-fnaim']);
    loadHektorNewAgenciesPromotions();
}

function loadHektorOffer() {
    if (isNewHektorOfferSelected()) {
        loadNewHektorOffer();
        return;
    }

    if (isOldHektorOfferSelected()) {
        loadOldHektorOffer();
    }
}

export function preRenderRecap() {
    /** Calculations for Hektor **/
    loadHektorOffer()

    /** Calculations for Studio Web **/
    loadTraductions('traductions');
    loadDomainNames('domain-names');
    loadGateways('passerelle-import', 'passerelle');
    loadHebergementMaintenance('hebergement');
    loadDomains('gsuite-domains');
    loadEmails('gsuite-emails');
    loadDomains ('exchange-domains');
    loadEmails('exchange-emails');
    loadModulesHomeBanners('modules-home-banners');
    loadModules('module-');
    loadPopinHomePage('popin-homepage')
    loadRefNatUniverses('ref-nat', 'universe');
    loadModulesContentPage('modules-content-page');
    loadCroContentPage('modules-content-page', 'cro');
    loadEmail();
    loadBlocksSite();

    /** Calculations for previsite **/
    const offers = getInDataState('offre-previsite.offre');
    loadPrevisiteProTypes(offers);
    const offersType = getInDataState('offre-previsite.type')
    loadPrevisiteFullAccessTypes(offersType);
    loadPrevisiteDuoTypes(offersType);
    loadPrevisiteCommunityManagement(offers);

    loadPrevisiteOptionsAdd('options-add');
    loadPrevisiteSalesOffers('spotvideo');
    loadPrevisiteSalesOffers('socialconnect');
    loadVirtualVisit('virtual-visit', ['equip-', 'equip2-']);
    loadMooveo('mooveo', 'mooveo-users-', 'stabi-mooveo-');
    loadPixHDR('pixhdr', 'pixhdr-users-');
    loadNewsfactory('newsfactory');

    /** Calculations for LePack offer **/
    loadLePackOffer();
    loadLePackAdditionalServices();
    loadLePackHektorOptions();
    loadLePackRecupData();
    loadLePackSalesOutlet();
    loadLePackDiscount();
    loadLePackOptionsIncluded();

    /** Load for ref nat **/
    loadRefNat();

    /** Calculations for Akademie **/
    loadAkademie();

    /** Calculations for Oskar **/
    const oskar = getInDataState('offre-oskar');
    loadOskarOffer(oskar, 'standard');
    loadOskarOffer(oskar, 'plus');

    loadOskarOptionsAdd(oskar, 'standard');
    loadOskarOptionsAdd(oskar, 'plus');
    loadOskarOption();

    /** Calculations of generic promotions **/
    loadGenericPromotions();

    loadLbiMedia();

    loadProtexaOffer();
    loadPrevisiteSmartpix();

    loadPerformanceOfferAdditionalUsers();
    loadPerformanceOfferPackingPrice();
    loadPerformanceDiscount();
    loadPerformanceSalesOutlet();
    loadPerformanceRecupData();
    loadPerformanceAdditionalServices()
    loadPerformanceHektorOptions();
    loadCommissioningLePack();
    loadCommissioningPerformance();
}

function loadHektorSubscription() {
    if (!isNewHektorOfferSelected()) {
        return;
    }

    const tarifName = 'hektor-new-subscription';

    const hektorOffers = getHektorOffers();

    hektorOffers && hektorOffers.forEach(hektorOffer => {
        const subscriptionTarif = getInTarifsState('hektor-new-subscription');

        const parent = resolveHektorParent(hektorOffer);

        if (subscriptionTarif) {
            addInFinalPrices(tarifName, {
                id: `${subscriptionTarif.label}|${parent}`,
                label: subscriptionTarif.label,
                price: subscriptionTarif.price[hektorOffer],
                dependsOn: hektorOffer,
                displayWhenParent: parent
            });
        }
    });
}

function loadPrevisiteSmartpix() {
    const type = getPrevisiteTypeFromOffer('standard');
    multipleClearFinalPriceOfTarifs(['option-previsite-pack-credit-mandataire','option-previsite-pack-credit-user', 'option-previsite-additionnal-mandataire', 'option-previsite-additionnal-user', 'option-previsite-abonnement-smartpix-mandataire', 'option-previsite-abonnement-smartpix-client'])

    if (type) {
        const optionsSmartPix = getInDataState(`offre-previsite.options-smartpix.${type}.quantities`);

        if (optionsSmartPix) {
            Object.entries(optionsSmartPix).forEach(([option, quantity]) => {
                const tarif = getInTarifsState(option);
                const label = getLabel(option);
                addInFinalPrices(option, {
                    id: tarif.id,
                    name : `${quantity} ${label}`,
                    price: quantity * tarif.price,
                    displayWhenParent: `parent-previsite`
                })
            });
        }
    }
}

function loadLePackOptionsIncluded() {
    const offer = getInDataState('offre-lepack');
    const type = getInDataState('offre-lepack.type')
    if (offer) {
        const optionsIncluded = getInDataState('offre-lepack.content.quantities.options-included');

        if (optionsIncluded) {
            Object.entries(optionsIncluded).forEach(([option, quantity]) => {
                const tarif = getInTarifsState(option);
                const label = getLabel(option);
                const price = resolvePrice(tarif, offer);

                addInFinalPrices(option, {
                    id: tarif.id,
                    name : `${quantity} ${label}`,
                    price:  price === 'inclus' ? 'inclus' : quantity * price,
                    displayWhenParent: `parent-${type}`
                })
            });
        }
    }
}

function loadProtexaOffer() {
    const protexa = getInDataState('protexa.type-contract');
    if (protexa) {
        const numberUser = getInDataState('customer-information-protexa.number-of-customer');
        const activityConcerned = getInDataState('customer-information-protexa.activity-concerned');
        const subscriptionType = getInDataState('customer-information-protexa.subscription-types');
        const subscriptionTypeLabel = getLabel(subscriptionType);
        const tarif = getInTarifsState(protexa);
        const price = tarif.price[activityConcerned];
        resetFinalPrices(tarif.id);
        if (tarif) {
            const protexaParent = `parent-${protexa}`;
            addInFinalPrices(tarif.id, {
                id: tarif.label,
                name: `${subscriptionTypeLabel} - ${numberUser} utilisateur(s)`,
                price: price,
                displayWhenParent: protexaParent
            });
        }
    }
}

const loadRefNat = () => {
    const refNat = getInDataState('ref-nat');

    if (refNat) {
        const { options = {} } = refNat;

        Object.entries(options).forEach(([option, value]) => {
            if (value) {
                const tarif = getInTarifsState(option);

                addInFinalPrices(option, {
                    id: tarif.id,
                    label: tarif.label,
                    price: tarif.price,
                    displayWhenParent: 'parent-webmark'
                })
            }
        })
    }
}

const loadAkademie = () => {
    const akademie = getInDataState('akademie');

    if (akademie) {
        const typeContrat = akademie['type-contract'];
        const month = (typeContrat === 'option-12-month') ? 12 : 36;

        const { content, offre } = akademie;
        let { quantities, options = {} } = content || {} ;

        options = Object.entries(options)
            .filter(([_, selected]) => selected)
            .map(([option, _]) => option);

        options.forEach(option => {
            const tarif = getInTarifsState(option)
            const quantity = quantities[option];
            const label = getLabel(`${tarif.label}`);

            resetFinalPrices(tarif.id);

            addInFinalPrices(option, {
                id: tarif.id,
                name: `${quantity} ${label}`,
                price: tarif.price[`${offre}-${month}`] * quantities[option],
                displayWhenParent: `parent-${offre}`
            });
        })
    }
}

function loadOskarOffer(oskar, offer) {
    if (oskar) {
        const tarif = getInTarifsState(`oskar-pack-${offer}`);
        if (tarif) {
            const oskarParent = `oskar-${offer}`;

            addInFinalPrices(tarif.label, {
                id: `${tarif.label}|parent-${oskarParent}`,
                label: tarif.label,
                price: tarif.price,
                displayWhenParent: `parent-${oskarParent}`
            });
        }
    }
}

const loadOskarOption = () => {
    const numberOfLot = getInDataState('oskar-recap.option-oskar-number-of-lots');

    multipleClearFinalPriceOfTarifs(['oskar-value-range-0-display','oskar-value-range-1-display', 'oskar-value-range-2-display', 'oskar-value-range-3-display', 'oskar-value-range-4-display'])

    if (numberOfLot > 0 && numberOfLot !== null && numberOfLot ) {
        const price = computeOskarPrice(numberOfLot);
        const tarif =  getInTarifsState('oskar-value-range-1-display');
        const oskar = getInDataState('offre-oskar');
        if (oskar){
            const { type } = oskar;
            if (type[0] === 'oskar-standard') {
                const quantity = numberOfLot > 25 ? numberOfLot-25 : numberOfLot;
                const label = numberOfLot > 25
                    ? `Lots : ${quantity} ${getLabel('option-oskar-plus-number-of-lot')} + 25 inclus`
                    : getLabel('option-oskar-plus-number-of-lot');
                addInFinalPrices(tarif.label, {
                    id : tarif.id,
                    name: `${numberOfLot} ${label}`,
                    price: price === 0 ? 'inclus' : parseFloat(price.toFixed(1)) ,
                    displayWhenParent: `parent-${type[0]}`,
                });
            }
            if (type[0] === 'oskar-plus') {
                const quantity = numberOfLot;
                const label = getLabel('option-oskar-plus-number-of-lot');
                addInFinalPrices(tarif.label, {
                    id : tarif.id,
                    name: `${quantity} ${label}`,
                    price: parseFloat(price.toFixed(1)) ,
                    displayWhenParent: `parent-${type}`,
                });
            }
        }
    }
}

const loadOskarOptionsAdd = (oskar, offer)  => {
    if (oskar) {
        const { [`oskar-${offer}`]: oskarValue, type } = oskar;
        if (oskarValue) {
            let { 'options-add': optionsAdd, quantities = false } = oskarValue;
            optionsAdd = Object.entries(optionsAdd)
                .filter(([_, selected]) => selected)
                .map(([option, _]) => option);

            optionsAdd.forEach(option => {
                const tarif = getInTarifsState(option);
                const quantity = quantities[option];
                const label = getLabel(`${tarif.label}`);
                addInFinalPrices(option, {
                    id: tarif.id,
                    name: quantity ? `${quantity} ${label}` : `${label}`,
                    price: quantities[option] ? tarif.price * quantities[option] : tarif.price,
                    displayWhenParent: `parent-${type}`,
                });
            });
        }
    }
};

const loadLePackOptions = ({ state, displayWhenParent }) => (options) => {
    if (!options) return;

    Object.entries(options).forEach(([name, included]) => {
        if (included) {
            const rateTarif = getInTarifsState(name);
            const price = resolvePrice(rateTarif, state);

            addInFinalPrices(name,{
                id: rateTarif.id,
                label: rateTarif.label,
                price: price,
                displayWhenParent: displayWhenParent
            })
        }
    });
}

function loadPerformanceOfferPackingPrice() {
    const performanceOfferState = getInDataState('offre-performance');

    if (performanceOfferState) {

        let tarif = getInTarifsState('performance-introductory-offer');

        addInFinalPrices('performance-introductory-offer',{
            id: tarif.id,
            label: tarif.label,
            price: tarif.price,
            displayWhenParent: 'parent-performance'
        });
    }
}

function loadPerformanceOfferAdditionalUsers() {
    const additionalUsersTarifName = 'performance-additional-users';
    const additionalUserCount = getInDataState('offre-performance.content.additional-users') ?? 0;

    if (additionalUserCount > 0) {
        const additionnalUserPrice = getInTarifsState('performance-additional-users')

        const performanceHektorTarif = getInTarifsState('performance-hektor');

        const additionalUsersTarif = getInTarifsState(additionalUsersTarifName);
        const price = additionalUserCount * additionnalUserPrice.price;
        const label = getLabel(additionalUsersTarif.label);

        const userCountBase = performanceHektorTarif.nbrOfUsers;

        addInFinalPrices(additionalUsersTarifName,{
            id: additionalUsersTarif.label,
            name: `${additionalUserCount} ${label} (${userCountBase} utilisateurs de base)`,
            price: price,
            displayWhenParent: 'parent-performance-hektor'
        });
    }
}

const loadPerformanceDiscount = () => {
    function getSelectedSiteOffer() {
        const selectedOffer = getInDataState('offre-site.offre-site-location');

        if (selectedOffer === 'nouveauxthemes-location') {
            const selectedModel = getInDataState(`offre-modele.modele-${selectedOffer}`);

            return getInTarifsState(`${selectedModel}-location`);
        }

        return getInTarifsState(selectedOffer);
    }

    const performanceOfferState = getInDataState('offre-performance');

        if (performanceOfferState) {
            setFinalPriceInTarifsState('offre-adhesion', 'inclus');
            if (getInDataState('modules-home-banners.module-estimation') === true) {
                setFinalPriceInTarifsState('module-estimation', 'inclus');
            }
            const selectedOffer = getSelectedSiteOffer();
            setFinalPriceInTarifsState(selectedOffer.id, 'inclus');
        }
}

function loadPerformanceSalesOutlet() {
    const [subscriptionTarifName, additionalUserTarifName] = [
        'performance-point-de-vente',
        'performance-point-de-vente-additional-users'
    ];

    const performanceOfferKey = 'offre-performance';

    setFinalPriceInTarifsState(subscriptionTarifName, null);

    const lePackOfferState = getInDataState(performanceOfferKey);
    const hasSalesOutletSubscription = getInDataState(`${performanceOfferKey}.content.abonnement-point-de-vente`);

    if (lePackOfferState && hasSalesOutletSubscription) {
        const salesOutletIds = getInDataState(`_order.${subscriptionTarifName}|point-de-vente`);

        const subscriptionTarif = getInTarifsState(subscriptionTarifName);
        const subscriptionPrice = subscriptionTarif.price;
        const baseUserCount = subscriptionTarif.nbrOfUsers;

        const additionalUserTarif = getInTarifsState(additionalUserTarifName);
        const additionalUserPrice = additionalUserTarif.price;

        salesOutletIds.forEach((id, index) => {
            const additionalUserCount = getInDataState(`${subscriptionTarifName}.point-de-vente|${id}/additionalUsers`);
            const finalPrice = subscriptionPrice + additionalUserCount * additionalUserPrice;

            const totalUserCount = baseUserCount + additionalUserCount;

            addInFinalPrices(subscriptionTarifName, {
                id: id,
                name: `Point de vente n°${index + 1} - ${totalUserCount} utilisateurs`,
                price: finalPrice,
            });
        });
    }
}

function loadLePackOffer() {
    const lePackOfferState = getInDataState('offre-lepack');

    if (lePackOfferState) {
        loadLePackOfferName(lePackOfferState);
        loadLePackOfferPackingPrice(lePackOfferState);
        loadLePackOfferAdditionalUsers(lePackOfferState);
    }
}

function loadLePackOfferName(lePackOfferState)
{
    const type = lePackOfferState.type;
    const tarif = getInTarifsState(`parent-${type}`)
    const offre = lePackOfferState.offre

    // We dynamicaly change the parent label to render the offer (evo or one)
    if (offre === 'one') {
         setInState(
             getTarifsState(),
             `parent-${type}`,
             { ...tarif, label: getLabel("le-pack-one") }
         );
    }

    if (offre === 'evo') {
        setInState(
            getTarifsState(),
            `parent-${type}`,
            { ...tarif, label: getLabel("le-pack-evo") }
        );
    }
}

function loadLePackOfferPackingPrice(lePackOfferState) {
    const { type } = lePackOfferState;

    let tarif = getInTarifsState("lepack-introductory-offer");

    // We dynamicaly add a group to render the correct sub offer (type)
    setInState(
        getTarifsState(),
        "lepack-introductory-offer",
        { ...tarif, group: type }
    );

    addInFinalPrices("lepack-introductory-offer",{
        id: tarif.id,
        label: tarif.label,
        price: resolvePrice(tarif, lePackOfferState),
        displayWhenParent: `parent-${type}`
    });
}

function loadLePackOfferAdditionalUsers(lePackState) {
    const type = lePackState.type;

    const additionalUsersTarifName = 'lepack-additional-users';
    const additionalUserCount = getInDataState(`offre-lepack.content.additional-users`) ?? 0;

    if (additionalUserCount > 0) {
        const lePackHektorTarif = getInTarifsState('lepack-hektor');

        const additionalUsersTarif = getInTarifsState(additionalUsersTarifName);
        const price = additionalUserCount * resolvePrice(additionalUsersTarif, lePackState);
        const label = getLabel(additionalUsersTarif.label);

        const userCountBase = resolveNbrOfUsers(lePackHektorTarif, lePackState);

        addInFinalPrices(additionalUsersTarifName,{
            id: additionalUsersTarif.label,
            name: `${additionalUserCount} ${label} (${userCountBase} utilisateurs de base)`,
            price: price,
            displayWhenParent: `parent-${type}-hektor`
        });
    }
}

const loadLePackDiscount = () => {
    function getSelectedSiteOffer() {
        const selectedOffer = getInDataState('offre-site.offre-site-location');

        if (selectedOffer === 'nouveauxthemes-location') {
            const selectedModel = getInDataState(`offre-modele.modele-${selectedOffer}`);

            return getInTarifsState(`${selectedModel}-location`);
        }

        return getInTarifsState(selectedOffer);
    }

    const lePackOfferState = getInDataState('offre-lepack');

    if (lePackOfferState) {
        const { type: offerType } = lePackOfferState;

        if (offerType === 'lepack-prospect' || offerType === 'lepack-creation-agence') {
            // Ref nat
            setFinalPriceInTarifsState('offre-conquete', 'inclus');
            if (getInDataState('modules-home-banners.module-estimation') === true) {
                setFinalPriceInTarifsState('module-estimation', 'inclus');
            }
            // Site
            const selectedOffer = getSelectedSiteOffer();
            setFinalPriceInTarifsState(selectedOffer.id, 'inclus');
        }
    }
}

function loadLePackAdditionalServices() {
    const lePackOfferState = getInDataState('offre-lepack');

    if (lePackOfferState) {
        const additionalTarifKey = 'option-lepack-additional-video-previsite-product';
        const optionKeysToLoad = ['option-lepack-virtual-visit', 'option-lepack-mooveo'];

        const type = getInDataState('offre-lepack.type');
        const options = getInDataState(`offre-lepack.content.options-included`);

        // Reset price
        setFinalPriceInTarifsState(additionalTarifKey, null, undefined, undefined);

        // Previsite products
        const INCLUDED_PRODUCT_LIMIT = 2;
        const previsiteProducts = Object.entries(options)
            .filter(([key, value]) => optionKeysToLoad.includes(key) && value === true)

        if (previsiteProducts.length > INCLUDED_PRODUCT_LIMIT) {
            const number = previsiteProducts.length - INCLUDED_PRODUCT_LIMIT;
            const tarif = getInTarifsState(additionalTarifKey);

            addInFinalPrices(additionalTarifKey, {
                id: tarif.id,
                name: `Produit(s) Previsite - ${number} produit(s) additionnel(s)`,
                price: number * resolvePrice(tarif, lePackOfferState),
                displayWhenParent: `parent-${type}`,
            })
        }
    }
}

function loadPerformanceAdditionalServices() {
    const performanceOfferState = getInDataState('offre-performance');

    if (performanceOfferState) {
        const additionalTarifKey = 'option-performance-additional-video-previsite-product';
        const optionKeysToLoad = ['option-performance-virtual-visit', 'option-performance-mooveo'];

        const options = getInDataState(`offre-performance.content.options-included`);

        // Reset price
        setFinalPriceInTarifsState(additionalTarifKey, null, undefined, undefined);

        // Previsite products
        const INCLUDED_PRODUCT_LIMIT = 1;
        const previsiteProducts = Object.entries(options)
            .filter(([key, value]) => optionKeysToLoad.includes(key) && value === true)

        if (previsiteProducts.length > INCLUDED_PRODUCT_LIMIT) {
            const number = previsiteProducts.length - INCLUDED_PRODUCT_LIMIT;
            const tarif = getInTarifsState(additionalTarifKey);

            addInFinalPrices(additionalTarifKey, {
                id: tarif.id,
                name: `Produit(s) Previsite - ${number} produit(s) additionnel(s)`,
                price: number * resolvePrice(tarif, performanceOfferState),
                displayWhenParent: `parent-performance`,
            })
        }
    }
}

function loadLePackHektorOptions() {
    const optionsKeys = ['options', 'options-add'];

    const lePackOfferState = getInDataState('offre-lepack');

    if (lePackOfferState) {
        const { type } = lePackOfferState;
        const displayWhenParent = `parent-${type}-hektor`;
        const loadForLePackOffer = loadLePackOptions({state: lePackOfferState, displayWhenParent});

        optionsKeys
            .map(key => getInDataState(`offre-lepack.content.${key}`))
            .forEach(loadForLePackOffer);
    }
}

function loadPerformanceHektorOptions() {
    const optionsKeys = ['options', 'options-add'];

    const performanceOfferState = getInDataState('offre-performance');

    if (performanceOfferState) {
        const displayWhenParent = `parent-performance-hektor`;
        const loadForPerformanceOffer = loadLePackOptions({state: performanceOfferState, displayWhenParent});

        optionsKeys
            .map(key => getInDataState(`offre-performance.content.${key}`))
            .forEach(loadForPerformanceOffer);
    }
}


function loadLePackRecupData() {
    const key = 'recup-data';

    const lePackOfferState = getInDataState('offre-lepack');

    if (lePackOfferState) {
        const type = lePackOfferState.type;

        const recupData = getInDataState(`offre-lepack.content.${key}`);
        const { logiciel, use } = recupData || {};

        if (logiciel && '' !== logiciel && 'logiciel' === use) {
            const tarifs = getInTarifsState(key);

            addInFinalPrices(key, {
                id: `${key}|parent-${type}`,
                label: tarifs.label,
                price: tarifs.price[logiciel.toLowerCase()],
                displayWhenParent: `parent-${type}-hektor`
            });
        }
    }
}

function loadPerformanceRecupData() {
    const key = 'recup-data';

    const performanceOfferState = getInDataState('offre-performance');

    if (performanceOfferState) {
        const type = performanceOfferState.offre;

        const recupData = getInDataState(`offre-performance.content.${key}`);
        const { logiciel, use } = recupData || {};

        if (logiciel && '' !== logiciel && 'logiciel' === use) {
            const tarifs = getInTarifsState(key);

            addInFinalPrices(key, {
                id: `${key}|parent-${type}`,
                label: tarifs.label,
                price: tarifs.price[logiciel.toLowerCase()],
                displayWhenParent: `parent-${type}-hektor`
            });
        }
    }
}

function loadCommissioningLePack() {
    const lePackOfferState = getInDataState('offre-lepack');

    if (lePackOfferState) {
        const { type } = lePackOfferState;
        let tarif = getInTarifsState('commissioning-iks');
        setInState(
            getTarifsState(),
            "commissioning-iks",
            { ...tarif, group: type }
        );

        addInFinalPrices('commissioning-iks',{
            id: tarif.id,
            label: tarif.label,
            price: resolvePrice(tarif, lePackOfferState),
            displayWhenParent: 'parent-lepack-creation-agence'
        });
    }
}

function loadCommissioningPerformance() {
    const performanceOfferState = getInDataState('offre-performance');

    if (performanceOfferState) {
        let tarif = getInTarifsState('commissioning-performance');
        setInState(
            getTarifsState(),
            "commissioning-performance",
            { ...tarif, group : performanceOfferState.offre });
        addInFinalPrices('commissioning-performance',{
            id: tarif.id,
            label: tarif.label,
            price: tarif.price,
            displayWhenParent: 'parent-performance'
        });
    }
}


function loadLePackSalesOutlet() {
    const [subscriptionTarifName, additionalUserTarifName] = [
        'lepack-point-de-vente',
        'lepack-point-de-vente-additional-users'
    ];

    const lePackOfferKey = 'offre-lepack';

    setFinalPriceInTarifsState(subscriptionTarifName, null);

    const lePackOfferState = getInDataState(lePackOfferKey);
    const hasSalesOutletSubscription = getInDataState(`${lePackOfferKey}.content.abonnement-point-de-vente`);

    if (lePackOfferState && hasSalesOutletSubscription) {
        const salesOutletIds = getInDataState(`_order.${subscriptionTarifName}|point-de-vente`);

        const subscriptionTarif = getInTarifsState(subscriptionTarifName);
        const subscriptionPrice = resolvePrice(subscriptionTarif, lePackOfferState);
        const baseUserCount = resolveNbrOfUsers(subscriptionTarif, lePackOfferState);

        const additionalUserTarif = getInTarifsState(additionalUserTarifName);
        const additionalUserPrice = resolvePrice(additionalUserTarif, lePackOfferState);

        salesOutletIds.forEach((id, index) => {
            const additionalUserCount = getInDataState(`${subscriptionTarifName}.point-de-vente|${id}/additionalUsers`);
            const finalPrice = subscriptionPrice + additionalUserCount * additionalUserPrice;

            const totalUserCount = baseUserCount + additionalUserCount;

            addInFinalPrices(subscriptionTarifName, {
                id: id,
                name: `Point de vente n°${index + 1} - ${totalUserCount} utilisateurs`,
                price: finalPrice,
            });
        });
    }
}

function loadHektorRecupData(key, tarifKeys) {
    const hektorOffers = getHektorOffers();

    const hektorOfferPath = resolveHektorOfferPath();

    hektorOffers && hektorOffers.forEach(hektorOffer => {
        const recupData = getInDataState(`${hektorOfferPath}.${hektorOffer}.${key}`);

        if (
            undefined !== recupData
            && recupData.hasOwnProperty('use')
            && 'logiciel' === recupData.use
            && recupData.hasOwnProperty('logiciel')
            && '' !== recupData.logiciel
        ) {
            const logiciel = recupData.logiciel;

            const parent = resolveHektorParent(hektorOffer);

            tarifKeys
                .map(tarifKey => getInTarifsState(tarifKey))
                .forEach(tarif => {
                    addInFinalPrices(tarif.id, {
                        id: `${tarif.id}|${parent}`,
                        label: tarif.label,
                        price: tarif.price[logiciel.toLowerCase()],
                        dependsOn: hektorOffer,
                        displayWhenParent: parent,
                    });
                })
        }
    });
}

function loadTraductions(key) {

    const traductions = getInDataState(key);
    const siteMode = getInDataState('offre-site.mode');

    let siteName = '';
    if(siteMode) {
        siteName = getInDataState(`offre-site.offre-site-${siteMode}`);
        siteName = siteName ? siteName.replace('-location','').replace('-vente','') : '';
    }

    siteMode && traductions && dispatch({
        type: LOAD_TRADUCTIONS,
        payload: {
            // Where to put in tarifs
            key: key,
            // Either location or vente
            mode: siteMode,
            // offer site name
            siteName: siteName,
            // From where in data
            list: traductions
        }
    });
}

function loadDomainNames(key) {
    const siteMode = getSiteMode();
    const domainNames = getInDataState(`${key}.domains`);

    siteMode && domainNames && dispatch({
        type: LOAD_DOMAIN_NAMES,
        payload: {
            // Where to put in tarifs
            key: key,
            // Either location or vente
            mode: siteMode,
            // From where in data
            list: domainNames
        }
    });
}

function loadGateways(key, name) {
    const gateways = getInDataState(`${key}.${name}`);

    gateways && dispatch({
        type: LOAD_GATEWAYS,
        payload: {
            // Where to put in tarifs
            key: key,
            // From where in data
            list: gateways
        }
    });
}

function loadHebergementMaintenance(key) {
    const siteMode = getInDataState(`offre-site.mode`);

    siteMode && dispatch({
        type: LOAD_HEBERGEMENT,
        payload: {
            // Where to put in tarifs
            key: key,
            mode: siteMode
        }
    });
}

function loadHektorAgencies([succursaleTarif, independantTarif, franchiseTarif], additionalUsersKey) {
    const hektorOffers = getHektorOffers();
    const hektorOfferPath = resolveHektorOfferPath();

    hektorOffers && hektorOffers.forEach(hektorOffer => {
        const hektorType = getHektorTypeFromHektorOffer(hektorOffer);

        loadHektorAgency(hektorOffer, hektorType, `${hektorOfferPath}.${hektorOffer}.agencies-succursale`, succursaleTarif, additionalUsersKey);
        loadHektorAgency(hektorOffer, hektorType, `${hektorOfferPath}.${hektorOffer}.agencies-independant`, independantTarif, additionalUsersKey);
        loadHektorAgency(hektorOffer, hektorType, `${hektorOfferPath}.${hektorOffer}.agencies-franchise`, franchiseTarif, additionalUsersKey);
    });
}

function loadHektorAgency(hektorOffer, hektorType, dataKey, tarifsKey, additionalUsersKey) {
    const agencies = getInDataState(dataKey);

    agencies && dispatch({
        type: LOAD_AGENCIES,
        payload: {
            key: tarifsKey,
            isNewHektorOffer: isNewHektorOfferSelected(),
            additionalUsersKey: additionalUsersKey,
            type: hektorType,
            offer: hektorOffer,
            list: agencies,
            // Additionl user price like ECM
            additionalUserPrice: getAdditionalUserPrice(hektorOffer, hektorType) ?? 0
        }
    });
}

function loadHektorAdditionalElements(key) {
    const hektorOffers = getHektorOffers();
    const hektorOfferPath = resolveHektorOfferPath();

    hektorOffers && hektorOffers.forEach(hektorOffer => {
        const supKeyFnaim = isFnaimOffer(hektorOffer) ? '-fnaim' : '';
        const hektorType = getHektorTypeFromHektorOffer(hektorOffer);
        const tarifs = getInTarifsState(key + supKeyFnaim);
        const hektorElements = getInDataState(`${hektorOfferPath}.${hektorOffer}.${key}` + supKeyFnaim);

        if (undefined !== hektorElements && 0 < hektorElements) {
            const additionalUserPrice = getAdditionalUserPrice(hektorOffer, hektorType);

            let price;
            if (isNewHektorOfferSelected()) {
                price = (tarifs.price[hektorOffer] + additionalUserPrice) * hektorElements;
            } else {
                price = (tarifs.price[hektorType] + additionalUserPrice) * hektorElements;
            }

            const parent = resolveHektorParent(hektorOffer);

            addInFinalPrices(key + supKeyFnaim, {
                id: `${tarifs.label}|${parent}`,
                label: tarifs.label,
                price: price,
                dependsOn: hektorOffer,
                displayWhenParent: parent,
            });
        }
    });
}

const getAdditionalUserPrice = (hektorOffer, hektorType) => {
    const hektorOfferPath = resolveHektorOfferPath();
    const optionsAdd = getInDataState(`${hektorOfferPath}.${hektorOffer}.options-add`);

    if (optionsAdd && optionsAdd['option-ecm']) {
        const tarif = getInTarifsState('option-ecm-users-add');

        return tarif && !tarif.includedIn[hektorOffer] && tarif.price[hektorType] ? tarif.price[hektorType] : 0;
    }

    return 0;
};

function loadHektorAdmins(adminKey, adminTarifsKey, superAdminKey, superAdminTarifsKey, suppAdminsKey, suppAdminsTarifsKey) {
    const hektorOffers = getHektorOffers();
    const hektorOfferPath = resolveHektorOfferPath();

    hektorOffers && hektorOffers.forEach(hektorOffer => {
        const supKeyFnaim = isFnaimOffer(hektorOffer) ? '-fnaim' : '';
        const dataKey = `${hektorOfferPath}.${hektorOffer}`;
        const hektorSuperAdmin = getInDataState(`${dataKey}.${superAdminKey}` + supKeyFnaim);
        const hektorAdmins = getInDataState(`${dataKey}.${adminKey}` + supKeyFnaim);
        const nbrAdditionalAdmins = getInDataState(`${dataKey}.${suppAdminsKey}` + supKeyFnaim);

        const parent = resolveHektorParent(hektorOffer);

        if (hektorSuperAdmin) {
            superAdminTarifsKey += supKeyFnaim;
            const tarifsSuperAdmin = getInTarifsState(superAdminTarifsKey);

            addInFinalPrices(superAdminTarifsKey, {
                id: `${tarifsSuperAdmin.label}|${parent}`,
                label: tarifsSuperAdmin.label,
                price: tarifsSuperAdmin.price[hektorOffer],
                dependsOn: hektorOffer,
                displayWhenParent: parent,
            });
        }

        if (hektorAdmins) {
            adminTarifsKey += supKeyFnaim;
            const tarifsAdmin = getInTarifsState(adminTarifsKey);

            addInFinalPrices(adminTarifsKey, {
                id: `${tarifsAdmin.label}|${parent}`,
                label: tarifsAdmin.label,
                price: tarifsAdmin.price[hektorOffer],
                dependsOn: hektorOffer,
                displayWhenParent: parent
            });
        }

        if (hektorAdmins && nbrAdditionalAdmins && 0 < nbrAdditionalAdmins) {
            suppAdminsTarifsKey += supKeyFnaim;
            const tarifsSuppAdmins = getInTarifsState(suppAdminsTarifsKey);

            addInFinalPrices(suppAdminsTarifsKey, {
                id: `${tarifsSuppAdmins.label}|${parent}`,
                label: tarifsSuppAdmins.label,
                price: tarifsSuppAdmins.price[hektorOffer] * nbrAdditionalAdmins,
                dependsOn: hektorOffer,
                displayWhenParent: parent
            });
        }
    });
}

function loadCommissioningHektor(key) {
    const hektorOffers = getHektorOffers();
    const hektorOfferPath = resolveHektorOfferPath();

    hektorOffers && hektorOffers.forEach(hektorOffer => {
        const commissioning = getInDataState(`${hektorOfferPath}.${hektorOffer}.${key}`);
        const tarifsCommissioning = getInTarifsState(key);

        if (true === commissioning) {
            const totalAgencies = getNumberOfHektorAgencies(hektorOffer);
            let basePrice = tarifsCommissioning.price[hektorOffer];

            if (inHektorOfferWithAgencies(hektorOffer) && !isNaN(basePrice)) {
                basePrice = basePrice * totalAgencies;
            }

            const parent = resolveHektorParent(hektorOffer);

            addInFinalPrices(key, {
                id: `${tarifsCommissioning.label}|${parent}`,
                label: tarifsCommissioning.label,
                price: basePrice,
                dependsOn: hektorOffer,
                onQuotation: 'sur devis' === basePrice,
                displayWhenParent: parent,
            });
        }
    });
}

function loadHektorOptions(key) {
    const hektorMixte = inHektorOfferMixte();
    const hektorOffers = getHektorOffers();
    const hektorOfferPath = resolveHektorOfferPath();

    hektorOffers && hektorOffers.forEach(hektorOffer => {

        const hektorType = getHektorTypeFromHektorOffer(hektorOffer);
        const isIkaPackType = isIkaLePackType(hektorType);

        const optionsHektor = getInDataState(`${hektorOfferPath}.${hektorOffer}.${key}`);

        optionsHektor && Object.entries(optionsHektor).forEach(([name, value]) => {
            if (true === value) {
                const tarifs = getInTarifsState(name);
                let optionPrice = tarifs.price[hektorOffer];

                if (!isIkaPackType && inHektorOfferWithMandataires(hektorOffer) && 'string' !== typeof optionPrice) {
                    optionPrice = hektorMixte ? getNumberOfHektorAdmins(hektorOffer.replace('mandataires','agences')) * optionPrice : getNumberOfHektorAdmins(hektorOffer) * optionPrice;
                } else if (!isIkaPackType && inHektorOfferWithAgencies(hektorOffer) && 'string' !== typeof optionPrice) {
                    optionPrice = getNumberOfHektorAgencies(hektorOffer) * optionPrice;
                }

                const parent = resolveHektorParent(hektorOffer);

                addInFinalPrices(name, {
                    id: `${tarifs.label}|${parent}`,
                    label: tarifs.label,
                    price: optionPrice,
                    dependsOn: hektorOffer,
                    displayWhenParent: parent,
                });
            }
        });
    });
}

function loadHektorOptionModelo(key) {
    const hektorOffers = getHektorOffers();
    const hektorOfferPath = resolveHektorOfferPath();

    hektorOffers && hektorOffers.forEach(hektorOffer => {
        const optionsAddHektor = getInDataState(`${hektorOfferPath}.${hektorOffer}.${key}`);

        optionsAddHektor && Object.entries(optionsAddHektor).forEach(([name, value]) => {
            if (name.startsWith('option-modelo-') && !name.includes('/')) {
                if (value === true) {
                    // Get the price of the option
                    const tarifsOption = getInTarifsState(name);
                    let optionPrice = tarifsOption.price[hektorOffer];

                    if (inHektorOfferWithAgencies(hektorOffer)) {
                        // Definition of the suffix
                        const suffix = '/agencies';
                        // Get the multiplier depending on option name with suffix
                        const multiplier = getInDataState(`${hektorOfferPath}.${hektorOffer}.${key}.${name}${suffix}`) ?? 0;
                        // Get the price of the option supp
                        const optionSuppPrice = getInTarifsState(`${name}${suffix}.price.${hektorOffer}`) ?? 0;

                        if (!isNaN(optionSuppPrice)) {
                            optionPrice = optionPrice + ( multiplier * optionSuppPrice );
                        }
                    }

                    const parent = resolveHektorParent(hektorOffer);

                    addInFinalPrices(name, {
                        id: `${tarifsOption.label}|${parent}`,
                        label: tarifsOption.label,
                        price: optionPrice,
                        onQuotation: 'sur devis' === optionPrice,
                        dependsOn: hektorOffer,
                        displayWhenParent: parent,
                    });
                }
            }
        });
    });
}

function loadHektorOptionsAdd(key) {
    const hektorOffers = getHektorOffers();
    const hektorOfferPath = resolveHektorOfferPath();

    hektorOffers && hektorOffers.forEach(hektorOffer => {
        const hektorType = getHektorTypeFromHektorOffer(hektorOffer);
        const isIkaPackType = isIkaLePackType(hektorType);

        const optionsAddHektor = getInDataState(`${hektorOfferPath}.${hektorOffer}.${key}`);

        optionsAddHektor && Object.entries(optionsAddHektor).forEach(([name, value]) => {
            if (true === value && !name.startsWith('option-modelo-')) {
                // Get tarifs of option
                const optionTarifs = getInTarifsState(name);
                let optionPrice = optionTarifs.price[hektorOffer];
                optionPrice = undefined === optionPrice ? optionTarifs.price[hektorType] : optionPrice;

                // Get counter of option
                const counterOption = optionWithCounter(hektorOffer, name, hektorOfferPath);
                let label = counterOption ? `${counterOption} ${getLabel(optionTarifs.label)}` : getLabel(optionTarifs.label);
                // Check if the option is not included in hektorType
                if (optionTarifs.includedIn && true === optionTarifs.includedIn[hektorType]) {
                    optionPrice = 'inclus';
                } else {
                    if (counterOption && optionTarifs.dynamicPrice && Array.isArray(optionTarifs.dynamicPrice[hektorOffer])) {
                        optionPrice = dynamicPriceCalculation(optionTarifs, counterOption, hektorOffer);
                    } else if (counterOption) {
                        optionPrice = counterOption * optionPrice;
                    } else if (!isIkaPackType) {
                        if (inHektorOfferWithAgencies(hektorOffer) && 'string' !== typeof optionPrice) {
                            optionPrice = getNumberOfHektorAgencies(hektorOffer) * optionPrice;
                        } else if (inHektorOfferWithMandataires(hektorOffer) && 'string' !== typeof optionPrice) {
                            const numberOfMandataires =  isNewHektorOfferSelected() ? getNumberOfHektorNewMandataires(hektorOffer) : getNumberOfHektorMandataires(hektorOffer);
                            const mandataireThreshold = hektorDependOfNumberOfMandataire(name);
                            if (mandataireThreshold) {
                                const priceForMandataireSupp = getInTarifsState(name).priceForMandataireSupp
                                label = label + ` (pour ${mandataireThreshold} mandataires + ${priceForMandataireSupp} euros supp par mandataire)`
                                optionPrice = computeTarifForThresholdDependentMandataires(mandataireThreshold, numberOfMandataires, optionPrice,name,priceForMandataireSupp);
                            } else {
                                optionPrice = numberOfMandataires * optionPrice;
                            }
                        }
                    }
                }

                const parent = resolveHektorParent(hektorOffer);

                addInFinalPrices(name, {
                    id: `${optionTarifs.label}|${parent}`,
                    name: label,
                    price: optionPrice,
                    onQuotation: 'sur devis' === optionPrice,
                    dependsOn: hektorOffer,
                    displayWhenParent: parent,
                });
            }
        });
    });
}

function loadHektorOptionsInc(key) {
    const hektorOffers = getHektorOffers();
    const hektorOfferPath = resolveHektorOfferPath();

    hektorOffers && hektorOffers.forEach(hektorOffer => {
        const hektorType = getHektorTypeFromHektorOffer(hektorOffer);
        const optionsIncHektor = getInDataState(`${hektorOfferPath}.${hektorOffer}.${key}`);

        optionsIncHektor && Object.entries(optionsIncHektor).forEach(([name, value]) => {
            if (value) {
                // Get tarifs of option
                const optionTarifs = getInTarifsState(name);
                let optionPrice = optionTarifs.price[hektorOffer];
                optionPrice = undefined === optionPrice ? optionTarifs.price[hektorType] : optionPrice;

                const parent = resolveHektorParent(hektorOffer);

                // Check if the option is not included in hektorType
                addInFinalPrices(name, {
                    id: optionTarifs.label,
                    label: optionTarifs.label,
                    price: optionPrice,
                    onQuotation: 'sur devis' === optionPrice,
                    dependsOn: hektorOffer,
                    displayWhenParent: parent,
                });
            }
        });
    });
}

function loadHektorType() {
    const hektorOffers = getHektorOffers();

    hektorOffers && hektorOffers.forEach(hektorOffer => {
        const hektorType = getHektorTypeFromHektorOffer(hektorOffer);
        const hektorTypeTarifs = getInTarifsState(hektorType);

        hektorTypeTarifs && addInFinalPrices(hektorType, {
            id: `${hektorTypeTarifs.label}|parent-${hektorOffer}`,
            label: hektorTypeTarifs.label,
            price: hektorTypeTarifs.price[hektorOffer],
            dependsOn: hektorOffer,
            displayWhenParent: `parent-${hektorOffer}`
        });
    });
}

function loadDomains(key) {
    const domainData = getInDataState(key);
    const produits = getInDataState('produits');
    const dataValues = domainData && Object.values(domainData).filter(value => value.includes('http://'));
    const domainName = dataValues && dataValues[0] && dataValues[0].replace('http://', '');
    let type = undefined;
    if (domainData &&  (Object.entries(domainData).filter(item => item[0].includes('transfer')))[0]) {
        type = domainData && (Object.entries(domainData).filter(item => item[0].includes('transfer')))[0][1];
        domainName && dispatch({
            type: LOAD_DOMAINS,
            payload: {
                // Where to put in tarifs
                key: key,
                // From where in data
                domain: domainName,
                // 'a-transferer' or 'a-acheter' or 'non-gere-ext'
                type: type,
                // product list
                produits: produits
            }
        });
    }
}

function loadEmails(key) {
    const emails = getInDataState(key);
    const listOfEmails = emails && Object.values(emails);

    listOfEmails && dispatch({
        type: LOAD_EMAILS,
        payload: {
            // Where to put in tarifs
            key: key,
            // From where in data
            list: listOfEmails
        }
    });
}

function loadModulesHomeBanners(dataStateKey) {
    const modulesHomeBanners = getInDataState(dataStateKey);
    const modulesHomeBannersConfig = getInDataState(`${dataStateKey}-config`);
    const hasConfig = undefined !== modulesHomeBannersConfig && null !== modulesHomeBannersConfig;

    if (modulesHomeBanners) {
        Object.entries(modulesHomeBanners).forEach(([key, value]) => {
            if (true === value) {
                const configKey = getConfigKey(key);

                if (hasConfig && modulesHomeBannersConfig.hasOwnProperty(configKey) && Object.entries(modulesHomeBannersConfig[configKey]).length) {
                    const configElements = modulesHomeBannersConfig[configKey];

                    const tarifs = getInTarifsState(key);
                    const priceKey = getInDataState(tarifs.priceDependsOn);
                    let finalPrice = tarifs.price[priceKey];

                    const versionedModules = [
                        'module-news',
                        'module-client-reviews',
                        'module-team',
                        'module-map-property-sol',
                        'module-cro-inclus',
                        'module-estimation',
                        'module-event'
                    ];

                    if (configElements.hasOwnProperty('version') && versionedModules.includes(key)) {
                        setFinalPriceInTarifsState(key, finalPrice, undefined, undefined, true);
                    } else if (configElements.hasOwnProperty('banner') && 'module-mixed' === configKey) {
                        setFinalPriceInTarifsState(key, finalPrice, undefined, undefined, true);
                    } else if (['module-cro', 'module-services','module-module-crush'].includes(key)) {
                        const freeLimit = tarifs.freeLimit;
                        const nbVersion = Object.entries(configElements).length;

                        finalPrice = nbVersion > freeLimit ? finalPrice * (nbVersion - freeLimit) : 'inclus'
                        setFinalPriceInTarifsState(key, finalPrice, undefined, undefined, true, 'location' === priceKey ? '/ Mois' : '');
                    }
                }
            }
        });
    }
}

function loadModules(prefixTarifsName) {
    const tarifsState = getTarifsState();

    Object.entries(tarifsState).forEach(([tarifsName, tarifs]) => {
        const moduleInData = getInDataState(`${tarifs.group}.${tarifs.label}`);

        if (tarifsName.startsWith(prefixTarifsName) && true === moduleInData) {
            const priceKey = getInDataState(tarifs.priceDependsOn);
            const finalPrice = tarifs.price[priceKey];

            setFinalPriceInTarifsState(tarifsName, finalPrice, undefined, undefined, true);
        }
    });
}

function loadRefNatUniverses(key, tarifsName) {
    const refPack = getInDataState(`${key}.offre-ref-nat`);
    const idsInDataState = getInDataState(`_order.${key}|univers-${refPack}`);
    const idsInTarifsState = getIdsOfFinalPrices(tarifsName);

    if (idsInDataState && idsInTarifsState && !sameArrays(idsInDataState, idsInTarifsState)) {
        // Reste previous finalPrices
        setFinalPriceInTarifsState(tarifsName, null);

        let nbrOfUniverses = 0;
        const freeLimit = getInTarifsState(`${tarifsName}.freeLimit`);
        const packFreeLimit = freeLimit[refPack];

        idsInDataState.forEach(id => {
            const basePath = `${key}.univers-${refPack}|${id}`;
            const type = getInDataState(`${basePath}/type`);

            const tarif = getInTarifsState(tarifsName);
            const price = _.isPureObject(tarif.price) ? tarif.price[refPack] : tarif.price;

            if (undefined !== type) {
                ++nbrOfUniverses;
                addInFinalPrices(tarifsName, {
                    id: id,
                    name: nbrOfUniverses > packFreeLimit ? `Univers n°${nbrOfUniverses} (supplémentaire)` : `Univers n°${nbrOfUniverses}`,
                    price: nbrOfUniverses > packFreeLimit ? price: 'inclus'
                });
            }
        });
    }
}

function loadModulesContentPage(dataStateKey) {
    const modulesContentPage = getInDataState(dataStateKey);
    const dataStateConfigKey = `${dataStateKey}-config`;
    const modulesContentPageConfig = getInDataState(dataStateConfigKey);
    const siteMode = getInDataState('offre-site.mode');

    modulesContentPage && Object.entries(modulesContentPage).forEach(([key, value]) => {
        if (true === value) {
            const configKey = getConfigKey(key);
            const tariff = getInTarifsState(key);
            const isForcedTarif = undefined !== tariff.isForcedTarif && true === tariff.isForcedTarif;

            if (modulesContentPageConfig && modulesContentPageConfig.hasOwnProperty(configKey)) {

                const configElements = modulesContentPageConfig[configKey];
                const isOwnChild = tariff.isOwnChild;
                const elementsException = tariff.elementsException;

                let isElementException = false;
                if (undefined !== elementsException && configElements) {
                    elementsException.forEach(value => {
                        if (!isElementException) {
                            isElementException = configElements.includes(value);
                        }
                    });
                }

                undefined !== isOwnChild && addInFinalPrices(key, {
                    id: `${key}|${isOwnChild}`,
                    label: isOwnChild,
                    price: isElementException ? tariff.priceForElementsException[siteMode] : tariff.price[siteMode],
                    independant: true,
                    isForcedTarif: isForcedTarif
                });

                configElements && dispatch({
                    type: LOAD_CONTENT_PAGE,
                    payload: {
                        // Which tarifs
                        key: key,
                        // Either location or vente
                        mode: siteMode,
                        // List of elements
                        list: configElements,
                        // Quantities
                        quantities: modulesContentPageConfig.quantities
                    }
                });
            } else {
                addInFinalPrices(configKey, {
                    id: configKey,
                    label: configKey,
                    price: tariff.price[siteMode],
                    independant: true,
                    isForcedTarif: isForcedTarif
                })
            }
        }
    });
}

function loadCroContentPage(path, tarifsName) {
    const config = getInDataState(path);
    const croConfig = getInDataState(`${path}-config.cro`);

    croConfig && path && dispatch({
        type: LOAD_CRO_CONTENT_PAGE,
        payload: {
            // Which tarifs
            key: tarifsName,
            // List of cro configs
            list: croConfig,
            // Config
            config: config
        }
    });
}

function loadEmail() {
    const config = getInDataState('emails');
    if(config && config.methode && config.quantities) {
        const counter = config.quantities ?? 1;
        const tarifs = getInTarifsState(`email-${config.methode}`);
        tarifs && null === tarifs.finalPrice && 0 < counter &&
            setFinalPriceInTarifsState(tarifs.id, tarifs.price * counter);
    }
}

function loadBlocksSite() {
    const listBlocks = [
        "block-redac1-portail",
        "block-redac2-portail",
        "block-redac3-portail",
        "block-redac4-portail",
        "page-and-listing-agent",
        "page-and-listing-agency"
    ];

    listBlocks.forEach(value => {
        const tarifs = getInTarifsState(value);
        if (tarifs && tarifs.priceDependsOn && null === tarifs.finalPrice) {
            const mode = getInDataState(tarifs.priceDependsOn);
            setFinalPriceInTarifsState(tarifs.id, tarifs.price[mode]);
        }
    })
}

function loadGenericPromotions() {
    const tarifsState = getTarifsState();

    Object.values(tarifsState).forEach(tarifs => {
        if (tarifs.hasOwnProperty('promotionOf') && tarifs.hasOwnProperty('promotions')) {
            // Retrieve ref tarifs, tarifs on which we will apply a promotion
            const refTarifs = getInTarifsState(tarifs.promotionOf);

            // For each promotion in our promotion tarifs
            tarifs.promotions.forEach(promotion => {
                const refFinalPrices = refTarifs.finalPrices.filter(finalPrice => finalPrice.displayWhenParent.includes(promotion.displayWhenParent));
                const refFinalPrice = refFinalPrices && refFinalPrices.length > 0 ? refFinalPrices[0] : undefined;

                if (refTarifs.finalPrices.length > 0) {
                    //refTarifs.finalPrices.forEach(price => console.log(price));
                }

                if (undefined !== refFinalPrice && 'inclus' !== refFinalPrice.price) {
                    // We calcul the finalPrice after promotion
                    const refPrice = refTarifs.price[promotion.price];
                    const needToBeTreated = refTarifs.finalPrices.some(finalPrice => finalPrice.id.includes(promotion.displayWhenParent));
                    const finalPrice = promotion.hasOwnProperty('percent') ? refPrice && (refPrice - (promotion.percent * refPrice / 100)) : promotion.value;

                    // And add it in finalPrices with the correct config
                    if (needToBeTreated && finalPrice !== undefined) {
                        addInFinalPrices(tarifs.id, {
                            id: `${tarifs.label}|${promotion.displayWhenParent}`,
                            label: tarifs.label,
                            price: 0 === finalPrice ? 'offert' : finalPrice,
                            dependsOn: promotion.id,
                            displayWhenParent: promotion.displayWhenParent
                        });
                    }
                }
            });
        }
    });
}

function loadHektorAgenciesPromotions() {
    const hektorOffers = getHektorOffers();

    if (hektorOffers) {
        loadHektorAgencyPromotion('hektor-agencies-succursale', 'hektor-agencies-succursale-promotion');
        loadHektorAgencyPromotion('hektor-agencies-independant', 'hektor-agencies-independant-promotion');
        loadHektorAgencyPromotion('hektor-agencies-franchise', 'hektor-agencies-franchise-promotion');
    }
}

function loadHektorNewAgenciesPromotions() {
    const hektorOffers = getHektorNewOffers();

    if (hektorOffers) {
        loadHektorAgencyPromotion('hektor-new-agencies-succursale', 'hektor-new-agencies-succursale-promotion');
        loadHektorAgencyPromotion('hektor-new-agencies-independant', 'hektor-new-agencies-independant-promotion');
        loadHektorAgencyPromotion('hektor-new-agencies-franchise', 'hektor-new-agencies-franchise-promotion');
    }
}

function loadHektorAgencyPromotion(tarifsKey, tarifsPromotionKey) {
    const agenciesFinalPrice = getInTarifsState(`${tarifsKey}.finalPrices`);
    const promotionLabel = '12 premiers mois';

    0 < agenciesFinalPrice.length && agenciesFinalPrice.map(agencyFinalPrice =>
        addInFinalPrices(tarifsPromotionKey, {
            id: `${agencyFinalPrice.name}|${agencyFinalPrice.displayWhenParent}`,
            name: `${agencyFinalPrice.name} - ${promotionLabel}`,
            price: 0 === agencyFinalPrice.priceUsers ? 'offert' : agencyFinalPrice.priceUsers,
            dependsOn: agencyFinalPrice.dependsOn,
            displayWhenParent: agencyFinalPrice.displayWhenParent
        })
    );
}

function loadPopinHomePage() {
    const siteOffer = getSiteMode();

    if (siteOffer) {
        const tarifs = getInTarifsState('popin-homepage');

        if (tarifs)
        {
            addInFinalPrices(tarifs.id, {
                id: `${tarifs}`,
                label: tarifs.label,
                price: tarifs.price[siteOffer],
            })
        }
    }
}

/**
 *
 * @param {string[]|undefined|null} offersType
 */
function loadPrevisiteFullAccessTypes(offersType) {

    const offer = getFullAccessTypeOffer();

    if (offersType && offersType.includes(offer)) {
        const type = getPrevisiteTypeFromOffer(offer);

        if (undefined !== type) {

            if (offer === 'full-access') {
                const tarifs = getInTarifsState('previsite-pack-full-access');

                const previsiteParent = `previsite-${offer}`;

                tarifs && addInFinalPrices(tarifs.label, {
                    id: `${tarifs.label}|parent-${previsiteParent}`,
                    label: tarifs.label,
                    price: tarifs.price[type],
                    displayWhenParent: `parent-${previsiteParent}`
                });
            }
            else {
                const tarifs = getInTarifsState('previsite-pack-full-access-plus');

                const previsiteParent = `previsite-${offer}`;

                tarifs && addInFinalPrices(tarifs.label, {
                    id: `${tarifs.label}|parent-${previsiteParent}`,
                    label: tarifs.label,
                    price: tarifs.price[type],
                    displayWhenParent: `parent-${previsiteParent}`
                });
            }

        }
    }
}

function loadPrevisiteDuoTypes(offersType) {

    const offer = getFullAccessTypeOffer();

    if (offersType && offersType.includes(offer)) {
        const type = getPrevisiteTypeFromOffer(offer);

        if (undefined !== type) {

            if (offer === 'smartpix-mooveo') {
                const tarifs = getInTarifsState('previsite-pack-smartpix-mooveo');

                const previsiteParent = `previsite-${offer}`;

                tarifs && addInFinalPrices(tarifs.label, {
                    id: `${tarifs.label}|parent-${previsiteParent}`,
                    label: tarifs.label,
                    price: tarifs.price[type],
                    displayWhenParent: `parent-${previsiteParent}`
                });
            }
            else {
                const tarifs = getInTarifsState('previsite-pack-smartpix-virtualvisit');

                const previsiteParent = `previsite-${offer}`;

                tarifs && addInFinalPrices(tarifs.label, {
                    id: `${tarifs.label}|parent-${previsiteParent}`,
                    label: tarifs.label,
                    price: tarifs.price[type],
                    displayWhenParent: `parent-${previsiteParent}`
                });
            }

        }
    }
}

/**
 *
 * @param {string[]|undefined|null} offers
 */
function loadPrevisiteProTypes(offers) {
    const offer = 'pro';

    if (offers && offers.includes(offer)) {
        const type = getPrevisiteTypeFromOffer(offer);

        if (undefined !== type) {
            const tarifs = getInTarifsState('previsite-pack-pro');

            const previsiteParent = `previsite-${offer}`;

            tarifs && addInFinalPrices(tarifs.label, {
                id: `${tarifs.label}|parent-${previsiteParent}`,
                label: tarifs.label,
                price: tarifs.price[type],
                displayWhenParent: `parent-${previsiteParent}`
            });
        }
    }
}

/**
 *
 * @param {string[]|undefined|null} offers
 */
function loadPrevisiteCommunityManagement(offers) {

    const offer = 'community-management';

    if (offers && offers.includes(offer)) {
        const tarif = getInTarifsState(`previsite-pack-${offer}`);

        if (tarif) {
            const previsiteParent = `previsite-${offer}`;

            addInFinalPrices(tarif.label, {
                id: `${tarif.label}|parent-${previsiteParent}`,
                label: tarif.label,
                price: tarif.price,
                displayWhenParent: `parent-${previsiteParent}`
            });
        }
    }
}

function loadPrevisiteOptionsAdd(key) {
    const mainOffer = 'offre-previsite';
    const offers = getOffers(mainOffer);

    offers && offers.forEach(offer => {
        const optionsAdd = getInDataState(`${mainOffer}.${offer}.${key}`);
        const type = getPrevisiteTypeFromOffer(offer);

        optionsAdd && Object.entries(optionsAdd).forEach(([name, value]) => {
            if (true === value) {
                // Get tarifs of option
                const optionTarifs = getInTarifsState(name);
                let optionPrice = _.isPureObject(optionTarifs.price) ? optionTarifs.price[offer] : optionTarifs.price;
                optionPrice = undefined === optionPrice && _.isPureObject(optionTarifs.price)
                    ? optionTarifs.price[type]
                    : optionPrice;

                // Get counter of option
                const counterOption = optionWithCounter(offer, name, mainOffer);

                if (optionTarifs.includedIn && true === optionTarifs.includedIn[type]) {
                    optionPrice = 'inclus';
                } else {
                    if (counterOption) {
                        optionPrice = counterOption * optionPrice;
                    }
                }
                const previsiteParent = `previsite-${offer}`;
                addInFinalPrices(name, {
                    id: `${optionTarifs.label}|parent-${previsiteParent}`,
                    label: optionTarifs.label,
                    price: optionPrice,
                    onQuotation: 'sur devis' === optionPrice,
                    displayWhenParent: `parent-${previsiteParent}`,
                    isForcedTarif: true
                })
            }
        });
    });
}

function loadPrevisiteSalesOffers(dataKey) {
    const offers = getInDataState(`${dataKey}.offre`);

    offers && offers.forEach(offer => {
        const counter = getInDataState(`${dataKey}.counter-${offer}`);
        const tarifs = getInTarifsState(offer);

        null === tarifs.finalPrice && 0 < counter &&
        setFinalPriceInTarifsState(tarifs.id, tarifs.price * counter);
    });
}

function loadVirtualVisit(dataKey, equipKeys) {
    const virtualVisitOffer = getInDataState(`${dataKey}.offre`);

    virtualVisitOffer && virtualVisitOffer.forEach(offerValue => {
        const offer = offerValue.replace('vv-', '');

        const counter = getInDataState(`${dataKey}.counter-${offer}`);
        const tarifs = getInTarifsState(`virtual-visit-${offer}`);

        if ('string' !== typeof tarifs.price) {
            null === tarifs.finalPrice && 0 < counter &&
            setFinalPriceInTarifsState(tarifs.id, tarifs.price * counter);
        } else {
            null === tarifs.finalPrice  &&
            setFinalPriceInTarifsState(tarifs.id, tarifs.price);
        }

        equipKeys.forEach((equipKey) => {
            const counterEquip = getInDataState(`${dataKey}.counter-${equipKey}${offer}`);
            const tarifsEquip = getInTarifsState(`virtual-visit-${equipKey}${offer}`);

            if ('string' !== typeof tarifsEquip.price) {
                null === tarifsEquip.finalPrice && 0 < counterEquip &&
                setFinalPriceInTarifsState(tarifsEquip.id, tarifsEquip.price * counterEquip);
            } else {
                null === tarifsEquip.finalPrice  &&
                setFinalPriceInTarifsState(tarifsEquip.id, tarifsEquip.price);
            }
        })
    });
}

function loadMooveo(dataKey, usersKey, stabilisateurKey) {
    const mooveoTypes = getInDataState(`${dataKey}.type`);

    mooveoTypes && mooveoTypes.forEach(type => {
        const tarifs = getInTarifsState(`${dataKey}-${type}`);
        const counter = getInDataState(`${dataKey}.counter-mooveo-${type}`);

        if ('string' !== typeof tarifs.price) {
            null === tarifs.finalPrice && 0 < counter &&
            setFinalPriceInTarifsState(tarifs.id, tarifs.price * counter);
        } else {
            null === tarifs.finalPrice  &&
            setFinalPriceInTarifsState(tarifs.id, tarifs.price);
        }

        const tarifsMes = getInTarifsState(`mes-${dataKey}-${type}`);

        if ('string' !== typeof tarifsMes.price) {
            null === tarifsMes.finalPrice && 0 < counter &&
            setFinalPriceInTarifsState(tarifsMes.id, tarifsMes.price * counter);
        } else {
            null === tarifsMes.finalPrice  &&
            setFinalPriceInTarifsState(tarifsMes.id, tarifsMes.price);
        }

        const tarifsUser = getInTarifsState(usersKey + type);
        const counterUsers = getInDataState(`${dataKey}.counter-users-${type}`);

        if ('string' !== typeof tarifsUser.price) {
            null === tarifsUser.finalPrice && 0 < counterUsers &&
            setFinalPriceInTarifsState(tarifsUser.id, tarifsUser.price * counterUsers);
        } else {
            null === tarifsUser.finalPrice &&
            setFinalPriceInTarifsState(tarifsUser.id, tarifsUser.price);
        }

        const tarifsStabilisateur = getInTarifsState(`${stabilisateurKey}${type}`);
        const counterStabilisateur = getInDataState(`${dataKey}.counter-stabilisateur-${type}`);

        if ('string' !== typeof tarifsStabilisateur.price) {
            null === tarifsStabilisateur.finalPrice && 0 < counterStabilisateur &&
            setFinalPriceInTarifsState(tarifsStabilisateur.id, tarifsStabilisateur.price * counterStabilisateur);
        } else {
            null === tarifsStabilisateur.finalPrice  &&
            setFinalPriceInTarifsState(tarifsStabilisateur.id, tarifsStabilisateur.price);
        }
    });
}

function loadPixHDR(dataKey, usersKey) {
    const pixHDRTypes = getInDataState(`${dataKey}.type`);

    pixHDRTypes && pixHDRTypes.forEach(type => {
        const tarifs = getInTarifsState(`${dataKey}-${type}`);
        const counter = getInDataState(`${dataKey}.counter-pixhdr-${type}`);

        if ('string' !== typeof tarifs.price) {
            null === tarifs.finalPrice && 0 < counter &&
            setFinalPriceInTarifsState(tarifs.id, tarifs.price * counter);
        } else {
            null === tarifs.finalPrice  &&
            setFinalPriceInTarifsState(tarifs.id, tarifs.price);
        }

        const tarifsUsers = getInTarifsState(usersKey + type);
        const counterUsers = getInDataState(`${dataKey}.counter-users-${type}`);

        if ('string' !== typeof tarifsUsers.price) {
            null === tarifsUsers.finalPrice && 0 < counterUsers &&
            setFinalPriceInTarifsState(tarifsUsers.id, tarifsUsers.price * counterUsers);
        } else {
            null === tarifsUsers.finalPrice && 0 < counterUsers &&
            setFinalPriceInTarifsState(tarifsUsers.id, tarifsUsers.price);
        }
    });
}

function loadNewsfactory(dataKey) {
    const newsfactory = getInDataState(`${dataKey}.type`);

    newsfactory
        ?.map(type => [
            type,
            getInTarifsState(`${dataKey}-${type}`),
            getInDataState(`${dataKey}.counter-newsfactory-${type}`)
        ])
        .forEach(([type, tarif, counter]) => {
            if ('string' !== typeof tarif.price) {
                null === tarif.finalPrice && 0 < counter &&
                setFinalPriceInTarifsState(tarif.id, tarif.price * counter);
            } else {
                null === tarif.finalPrice &&
                setFinalPriceInTarifsState(tarif.id, tarif.price);
            }

            const expensesTarif = getInTarifsState(`mes-${dataKey}-${type}`);

            if ('string' !== typeof expensesTarif.price) {
                null === expensesTarif.finalPrice && 0 < counter &&
                setFinalPriceInTarifsState(expensesTarif.id, expensesTarif.price * counter);
            } else {
                null === expensesTarif.finalPrice  &&
                setFinalPriceInTarifsState(expensesTarif.id, expensesTarif.price);
            }
        })
}

const loadLbiMedia = () => {
    const lbiMediaState = getInDataState('offre-lbi-media');
    const lbiMediaSoftware = getInDataState('offre-lbi-media.software');

    if (lbiMediaSoftware && lbiMediaState.offre.includes('software')) {
        const lbiMedia = getInDataState('offre-lbi-media');

        const { software } = lbiMedia;
        let { quantities } = software;
        const value = quantities.screen;

        if (value > 0 ) {
            const tariffs = getInTarifsState("lbi-media-screen-count")
            const label = getLabel(`recap/lbi-media-screen-count`);
            const labelSupp = "(Abonnement de 12 mois renouvelable par tacite reconduction)";
            const orientations = getInDataState("offre-lbi-media.software.orientation.value");
            const portraitLabel = "Portrait";
            const paysageLabel = "Paysage";

            const counts = orientations.reduce((acc, item) => {
                if (!acc[item.orientation]) {
                    acc[item.orientation] = 0;
                }

                acc[item.orientation]++;

                return acc;
            }, {});

            if ('portrait' in counts) {
                const portraitCount = counts.portrait;

                addInFinalPrices("lbi-media-screen-count", {
                    id: `${tariffs.label}-portrait`,
                    name: `${portraitCount} ${label} ${portraitLabel} ${labelSupp}`,
                    price: tariffs.price * portraitCount,
                    displayWhenParent: "parent-offre-lbi-media-software"
                });
            }

            if ('paysage' in counts) {
                const paysageCount = counts.paysage;

                addInFinalPrices("lbi-media-screen-count", {
                    id: `${tariffs.label}-paysage`,
                    name: `${paysageCount} ${label} ${paysageLabel} ${labelSupp}`,
                    price: tariffs.price * paysageCount,
                    displayWhenParent: "parent-offre-lbi-media-software"
                });
            }

            const playerTariff = getInTarifsState("lbi-media-software-player");
            const installationTariff = getInTarifsState("lbi-media-software-installation-and-setup");

            resetFinalPrices(playerTariff.id);

            orientations.forEach((item, index) => {
                let result = [];
                if (item?.player && item?.settings) {
                    result = ['Player, Installation et paramétrage', playerTariff.price + installationTariff.price];
                } else if (item?.player) {
                    result = ['Player', playerTariff.price];
                } else if (item?.settings) {
                    result = ['Installation et paramétrage', installationTariff.price];
                }

                if (result.length > 0) {
                    addInFinalPrices('lbi-media-software-player', {
                        id: `lbi-media-software-player-${index}`,
                        name:  `${result[0]} pour 1 Écran ${getLabel(item.orientation)}`,
                        price : result[1],
                        displayWhenParent: "parent-offre-lbi-media-software"
                    })
                }
            })

            loadLbiMediaSoftwareOption(software);
        }
    }

    loadScreenOffer(lbiMediaState);
    loadLedOffer(lbiMediaState);
}

const loadLbiMediaSoftwareOption = (softwareState) => {
     if (softwareState) {
         const remoteControlSoftware = softwareState['remote-control-software'];

         if (remoteControlSoftware === true) {
             const tarifs = getInTarifsState("remote-control-software")
             const label = getLabel(`recap/${tarifs.label}`)

             addInFinalPrices("remote-control-software" , {
                 id: `${tarifs.label}`,
                 name: `${label}`,
                 price: tarifs.price,
                 displayWhenParent: "parent-offre-lbi-media-software"
             });
         }
     }
}
