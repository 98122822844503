import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import Toggle from '../Toggle';
import PriceElement from '../PriceElement';
import { UPDATE_DATA_VALUE } from '../../Store/actions';
import { getLabel } from '../../Utils/labels';
import { borderGrey } from '../../Utils/styleConstants';
import {
    getInDataState,
    setInDataState,
    setFinalPriceInTarifsState
} from '../../Utils/storeHelpers';

const StyledContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const ModuleStructure = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    padding: 3vh 0;
    border-bottom: 1px solid ${borderGrey};

    &:first-child {
        padding: 0 0 3vh;
    }

    &::after {
        order: -1;
        content: '';
        flex-grow: 1;
        margin: 0 30px 0 0;
        border-bottom: 1px dotted grey;
    }
`;

const ToggleStructure = styled.div`
    display: flex;
    align-items: center;
    min-width: 35%;
    order: -1;
`;

const OptionName = styled.p`
    padding: 0 0 0 10%;
`;

const PerformanceOptions = ({options, ...props}) => {
    const { pathInDataState } = props;

    const [toggles, setToggles] = useState(null);

    useEffect(() => {
        if (!toggles) {
            const result = options.map(option => getInDataState(`${pathInDataState}.${option.name}`));
            setToggles(result);
        }
    }, [toggles, pathInDataState, options]);

    // Callback when toggle change
    const handleToggleChange = useCallback((optionValue, optionIndex, optionName) => {
        setInDataState(`${pathInDataState}.${optionName}`, optionValue);

        // If toggle is turned off, we reset the final price
        if (!optionValue) {
            setFinalPriceInTarifsState(optionName, null, undefined, undefined);
        }

        const result = toggles.map((value, index) => index === optionIndex ? !value : value)
        setToggles(result);
    }, [pathInDataState, toggles]);

    return (
        toggles && (
            <StyledContainer>
                {
                    options.map((option, index) => (
                        <ModuleStructure key={index}>
                            <ToggleStructure>
                                <Toggle
                                    index={index}
                                    action={UPDATE_DATA_VALUE}
                                    masterId={props.masterId}
                                    name={option.name}
                                    value={toggles[index]}
                                    callback={handleToggleChange}
                                />
                                <OptionName>{getLabel(option.label)}</OptionName>
                            </ToggleStructure>
                            <PriceElement
                                tarifsName={option.name}
                                dependsOn={props.pricesDependsOn}
                            />
                        </ModuleStructure>
                    ))
                }
            </StyledContainer>
        )
    );
};

export default PerformanceOptions;
