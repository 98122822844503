import { useEffect } from 'react';
import { multipleSetInDataState } from '../Utils/storeHelpers';

const AutoStateSetter = (props) => {
    useEffect(() => {
        multipleSetInDataState(props.setInDataState);
    }, [props.setInDataState]);

    return null;
};

export default AutoStateSetter;
