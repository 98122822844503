import React, {useState} from 'react';
import styled from 'styled-components';
import PriceElement from '../PriceElement';
import salesOutletImage from '../../Images/point-vente-supplementaire.png';
import {
    SubscriptionContainer,
    SubscriptionStructure,
    easeOutBack,
    borderGrey,
    green,
} from '../../Utils/styleConstants';
import {getTarifsInfoByTarifsName, manualDependsOnTarifsInfo} from "../../Utils/jsonInterpreter";
import Toggle from "../Toggle";
import {UPDATE_DATA_VALUE} from "../../Store/actions";
import {getInDataState, multipleClearInDataState, setInDataState} from "../../Utils/storeHelpers";
import CheckmarkContainer from "../Icons/CheckmarkContainer";

const StyledSubscriptionContainer = styled(SubscriptionContainer)`
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 4vh;
    border-radius: 4px;
    transition: all 0.5s ${easeOutBack};

    ${({ checked }) => checked ? `box-shadow: 0 0 0 3px ${green};` : `border: 1px solid ${borderGrey};`}
`;

const Image = styled.img`
    padding-right: 5%;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    height: 100%;
    padding: 1vh 0;
`;

const Title = styled.div`
    font-size: 42px;
    font-weight: bold;
    letter-spacing: 1px;
`;

const AdditionalElementStructure = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
`;

const StyledRowContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
`;

const StyledWidthWrapper = styled.div`
    width: 40%;
`;

const PerformanceSalesOutletSubscription = props => {
    const { subscriptionTarif, additionalUserTarif } = props

    const [toggle, setToggle] = useState(getInDataState(props.pathInDataState) ?? false);

    const subscriptionTarifInfo = getTarifsInfoByTarifsName(subscriptionTarif.name);
    const offerSubscriptionTarifInfo = manualDependsOnTarifsInfo(subscriptionTarifInfo, subscriptionTarif.dependsOn);
    const defaultUsersCount = offerSubscriptionTarifInfo.nbrOfUsers || 0;

    const handleToggle = () => {
        setToggle(state => {
            setInDataState(props.pathInDataState, !state);
            multipleClearInDataState(props.clearOnChangeInDataState);

            return !state;
        });
    }

    return (
        <StyledSubscriptionContainer checked={toggle}>
            <Image src={salesOutletImage} alt='Point de vente supplémentaire' />
            <Content>
                <Title>Point(s) de vente supplémentaire(s)</Title>
                <StyledRowContainer>
                    {
                        subscriptionTarif && (
                            <SubscriptionStructure>
                                <PriceElement
                                    tarifsName={subscriptionTarif.name}
                                    dependsOn={subscriptionTarif.dependsOn}
                                    fontSize='6em'
                                />
                                <p>{`Pour ${defaultUsersCount} utilisateurs`}</p>
                            </SubscriptionStructure>
                        )
                    }
                    <StyledWidthWrapper>
                        <Toggle
                            action={UPDATE_DATA_VALUE}
                            pathInDataState={props.pathInDataState}
                            name={props.name}
                            value={toggle}
                            callback={handleToggle}
                        />
                    </StyledWidthWrapper>
                </StyledRowContainer>
                {
                    additionalUserTarif && (
                        <AdditionalElementStructure>
                            <div>
                                <p>Utilisateur(s) supplémentaire(s)</p>
                                <p>10 signatures et 50 sms inclus</p>
                            </div>
                            <StyledWidthWrapper>
                                <PriceElement
                                    tarifsName={additionalUserTarif.name}
                                    dependsOn={additionalUserTarif.dependsOn}
                                />
                            </StyledWidthWrapper>
                        </AdditionalElementStructure>
                    )
                }
            </Content>
            <CheckmarkContainer checked={toggle} notBottomCheckmark={true} />
        </StyledSubscriptionContainer>
    );
};

export default PerformanceSalesOutletSubscription;
