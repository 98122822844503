import React, { useEffect } from 'react';
import styled from 'styled-components';
import PriceElement from '../PriceElement';
import AgencyConfiguration from '../AgencyConfiguration';
import PromoTag from '../PromoTag';
import PromoFnaimTag from '../PromoFnaimTag';
import personnalisationHektor from '../../Images/HK_offert.png';
import logoHektor from '../../Images/logo-hektor.png';
import { getLabel } from '../../Utils/labels';
import { manualDependsOnTarifsInfo } from '../../Utils/jsonInterpreter';
import {
    SubscriptionContainer,
    SubscriptionStructure,
    SubscriptionInsteadOf,
    SubscriptionLogo,
    SubscriptionOfferName,
    SubscriptionCommissioning,
    SubscriptionAdditionalUser
} from '../../Utils/styleConstants';
import {
    setInDataState,
    getInTarifsState
} from '../../Utils/storeHelpers';

const ImageContainer = styled.div`
  padding: 0 5% 0 0;
`

const Image = styled.img`
  object-fit: fill;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: calc(100% - 518.88px);
`;

const LogoStructure = styled.div`
    display: flex;
    flex-direction: column;
`;

const LabelInStructure = styled.p`
    order: -1;
    white-space: nowrap;
`;

const AgenciesConfiguration = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 1em 1.5%;
`;

function AbonnementReseau({ offer, tarif, pathInDataState, commissioningName, promoTag, offerName, additionalUsers, promoFnaimTag }) {
    const tarifsInfo = manualDependsOnTarifsInfo(getInTarifsState(tarif), offer);

    useEffect(() => {
        commissioningName && setInDataState(`${pathInDataState}.${commissioningName}`, true);
    }, [pathInDataState ,commissioningName])

    return (
        <SubscriptionContainer>
            <ImageContainer>
                <Image src={personnalisationHektor} alt='Personnalisation offerte' />
            </ImageContainer>
            <Content>
                <LogoStructure>
                    <SubscriptionLogo src={logoHektor} alt="Logo Hektor" />
                    <SubscriptionOfferName>{offerName}</SubscriptionOfferName>
                </LogoStructure>
                {
                    tarifsInfo && (
                        <SubscriptionStructure>
                            <PriceElement
                                tarifsName={tarif}
                                dependsOn={offer}
                                fontSize='6em'
                            />
                            {
                                tarifsInfo.insteadOf && typeof tarifsInfo.insteadOf !== 'object' && (
                                    <SubscriptionInsteadOf>{`Au lieu de ${tarifsInfo.insteadOf} € ${tarifsInfo.priceUnit}`}</SubscriptionInsteadOf>
                                )
                            }
                            {
                                tarifsInfo.nbrOfUsers && typeof tarifsInfo.nbrOfUsers !== 'object' && (
                                    <p>{`Compte Agence pour ${tarifsInfo.nbrOfUsers} utilisateurs`}</p>
                                )
                            }
                        </SubscriptionStructure>
                    )
                }
                {
                    additionalUsers && (
                        <SubscriptionAdditionalUser>
                            <LabelInStructure>Utilisateur(s) supplémentaire(s)</LabelInStructure>
                            <PriceElement
                                tarifsName={additionalUsers}
                                dependsOn={offer}
                            />
                        </SubscriptionAdditionalUser>
                    )
                }
                {
                    commissioningName && (
                        <SubscriptionCommissioning>
                            <LabelInStructure>{getLabel(commissioningName)}</LabelInStructure>
                            <PriceElement
                                tarifsName={commissioningName}
                                dependsOn={offer}
                            />
                        </SubscriptionCommissioning>
                    )
                }
            </Content>
            <AgenciesConfiguration>
                <AgencyConfiguration
                    type='Succursale'
                    pathInDataState={pathInDataState}
                    hektorOffer={offer}
                    dataKey='agencies-succursale'
                    tarifsKey='hektor-new-agencies-succursale'
                    commissioningName={commissioningName}
                />
                <AgencyConfiguration
                    type='Indépendant'
                    pathInDataState={pathInDataState}
                    hektorOffer={offer}
                    dataKey='agencies-independant'
                    tarifsKey='hektor-new-agencies-independant'
                    commissioningName={commissioningName}
                />
                <AgencyConfiguration
                    type='Franchise'
                    pathInDataState={pathInDataState}
                    hektorOffer={offer}
                    dataKey='agencies-franchise'
                    tarifsKey='hektor-new-agencies-franchise'
                    commissioningName={commissioningName}
                />
            </AgenciesConfiguration>
            {
                promoTag && (
                    <PromoTag
                        tarifsName={`${tarif}-promotion`}
                        promotionId={offer}
                        {...promoTag}
                    />
                )
            }
            {
                promoFnaimTag && (
                    <PromoFnaimTag {...promoFnaimTag} />
                )
            }
        </SubscriptionContainer>
    );
};

export default AbonnementReseau;
