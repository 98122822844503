import React, { useCallback } from 'react';
import styled from 'styled-components';
import CheckableOption from './Option/CheckableOption';
import AdvancedOptionHektor from './OptionHektor/AdvancedOptionHektor';
import SpecificOptionHektor from "./OptionHektor/SpecificOptionHektor";
import ModeloOptionHektor from './OptionHektor/ModeloOptionHektor';
import WithCounterChoiceOption from "./Option/WithCounterChoiceOption";
import Notes from './Notes';
import {
    getDynamicHektorOffer,
    getDynamicPathInDataState,
    getDynamicPathToDependsOn,
    getHektorTypeFromHektorOffer,
    getInDataState,
    getInTarifsState,
    isIkaLePackType
} from '../Utils/storeHelpers';

const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const OptionHektorAdd = props => {
    const hektorOffer = getDynamicHektorOffer(props.isSpecialCase, props.pathForOffer, props.hektorOffer);
    const pathInDataState = getDynamicPathInDataState(props.isSpecialCase, props.pathInDataState, hektorOffer);
    const hektorType = props.hektorType ? props.hektorType : getHektorTypeFromHektorOffer(hektorOffer);
    const isIkaPackType = isIkaLePackType(hektorType);
    const partOfId = !isIkaPackType ? hektorOffer : undefined;

    const isOptionChecked = useCallback((option) => {
        return getInDataState(`${pathInDataState}.${option.name}`) ??
            (getInTarifsState(`${option.name}.includedIn`) && getInTarifsState(`${option.name}.includedIn`)[hektorType]);
    }, [hektorType, pathInDataState]);

    return (
        <Container>
            {
                props.options.map((option, index) => (
                    <React.Fragment key={index}>
                        {
                            'checkableOption' === option.type && (
                                <CheckableOption
                                    pathInDataState={pathInDataState}
                                    image={option.image}
                                    name={option.name}
                                    label={option.label}
                                    tarifsName={option.name}
                                    dependsOn={hektorOffer}
                                    partOfId={partOfId}
                                    additionalDependsOn={getInDataState(getDynamicPathToDependsOn(option.isSpecialCase, option.pathToDependsOn, hektorOffer))}
                                    setItselfFalse={option.setItselfFalse}
                                    setInDataState={option.setInDataState}
                                    full={option.full}
                                    fullOptions={option.fullOptions}
                                    disabled={true === option.disabled}
                                    pathInDataStateQuantity={option.pathInDataStateQuantity}
                                    counterLabel={option.counterLabel}
                                    counterDependsOn={option.counterDependsOn}
                                    default={option.default}
                                    checked={() => isOptionChecked(option)}
                                    hektorType={hektorType}
                                />
                            )
                        }
                        {
                            'advancedOptionHektor' === option.type && (
                                <AdvancedOptionHektor
                                    hektorOffer={hektorOffer}
                                    hektorType={hektorType}
                                    pathInDataState={pathInDataState}
                                    {...option}
                                />
                            )
                        }
                        {
                            'specificOptionHektor' === option.type && (
                                <SpecificOptionHektor
                                    hektorOffer={hektorOffer}
                                    hektorType={hektorType}
                                    pathInDataState={pathInDataState}
                                    {...option}
                                />
                            )
                        }
                        {
                            'modeloOptionHektor' === option.type && (
                                <ModeloOptionHektor
                                    hektorOffer={hektorOffer}
                                    hektorType={hektorType}
                                    pathInDataState={pathInDataState}
                                    {...option}
                                />
                            )
                        }
                        {
                            'withChoice' === option.type && (
                                <WithCounterChoiceOption
                                    hektorOffer={hektorOffer}
                                    hektorType={option.hektorType}
                                    pathInDataState={pathInDataState}
                                    {...option}
                                />
                            )
                        }
                    </React.Fragment>
                ))
            }
            {
                props.withNotes && (
                    <Notes
                        pathInDataState={`${pathInDataState}.notes`}
                        name='notes'
                        padding='30px 1%'
                    />
                )
            }
        </Container>
    );
};

export default OptionHektorAdd;
